import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormArray, FormControl, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { AuthenticationService } from '../../services/auth/authentication.service';
import { ApiService } from '../../services/api/api.service';
import { FormatService } from '../../services/format/format.service';
import * as moment from "moment/moment";

declare const $:any;

@Component({
  selector: 'app-content-video',
  templateUrl: './content-video.component.html',
  styleUrls: ['./content-video.component.css']
})
export class ContentVideoComponent implements OnInit {
  showFormAdd: any = { 'isFormSearch': 0, 'isType': 'add',};
  isForm : any;
  isDetailData : any ;
  isData : any = [];
  isOffset: number = 0;
  isTotalVw : number = 0;
  isTotalAll : number = 0;

  isKeyword : string = '';

  attachment: any = [];
  isLoading = false;

  constructor(
    private fb: FormBuilder,
    private toastr: ToastrService,
    private authService: AuthenticationService,
    private apiService: ApiService,
    private formatService: FormatService,
  ) { }

  ngOnInit(): void {
    this.getData();
    this.resetForm();
  }
  async resetForm(){
    this.isForm = this.fb.group({
      id : new FormControl(''),
      admin_id : new FormControl(this.authService.getSession().id, [Validators.required]),
      title : new FormControl('', [Validators.required]),
      description : new FormControl('', [Validators.required]),
      code : new FormControl('', [Validators.required]),
    })
  }
  async onReset() {
    this.isData    = await [];
    this.isOffset = await 0;
    this.isTotalVw = await 0;
    this.isTotalAll = await 0;
    this.isKeyword = await '';
    await this.resetForm();
    await this.getData();
  }
  async onSearch($event: any){
    this.isData    = await [];
    this.isOffset = await 0;
    this.isTotalVw = await 0;
    this.isTotalAll = await 0;
    this.isKeyword = await ($event['keyword']?$event['keyword']:'');
    this.getData();
  }
  async onDelete(id:number){
    try{
      await this.formatService.alertBeforeDelete().then(async (result) => {
        if (result.value) {
          this.apiService.methodDelete(`video/${id}`, this.authService.getToken()).subscribe(async (response)=>{
            if(response.status == 200) {
              await this.toastr.success(`Berhasil dihapus`);
              this.onReset();
            }
          });
        }
      });
    }catch(error){
      console.log(error);
    }
  }
  async getData(){
    let searchData = {"keyword":this.isKeyword};
    try{
      this.apiService.methodGet(`video/${this.isOffset}/${encodeURIComponent(JSON.stringify(searchData))}`,this.authService.getToken()).subscribe((response)=>{
        if(response.status == 200) {
          (response.total ? response.data.forEach((element: any) => {
            element.photo = `http://img.youtube.com/vi/${element.code}/0.jpg`
            element.createdAt = moment(element.createdAt).locale('id').format("D MMMM YYYY HH:mm");
            this.isData.push(element);
          }) : false);
          this.isTotalVw = this.isData.length;
          this.isTotalAll = response.total;
        }
      })
    }catch(error){
      console.log(error);
    }
  }
  async loadMore() {
    this.isOffset = await this.isTotalVw;
    await this.getData();
  }
  async onShowAdd(){
    this.resetForm();
    $('#formData').modal({backdrop: 'static', keyboard: false})
  }
  async onSubmit(){
    try{
      if(this.isForm.valid){
        this.isLoading = true;
        if(this.isForm.value.id){
          this.apiService.methodPut(`video/${this.isForm.value.id}`, this.isForm.value, this.authService.getToken()).subscribe(async(response)=>{
            $('#formData').modal('hide');
            this.isLoading = false;
            if (response.status == 200) {
              await this.toastr.success(`Berhasil diupdate`);
              await this.onReset();
            } else {
              await this.toastr.error(response.message || "Gagal memuat data");
            }
          });
        }else{
          this.apiService.methodPost(`video`, this.isForm.value, this.authService.getToken()).subscribe(async(response)=>{
            $('#formData').modal('hide');
            this.isLoading = false;
            if (response.status == 200) {
              await this.toastr.success(`Berhasil disimpan`);
              await this.onReset();
            } else {
              await this.toastr.error(response.message || "Gagal memuat data");
            }
          });
        }
      }
    }catch(error){

    }
  }
  async onEdit(i:number){
    try{
      this.resetForm();
      const dataEdit: any = Object.assign({}, this.isData[i]);
      await this.isForm.patchValue(dataEdit);
      await $('#formData').modal({ backdrop: 'static', keyboard: true, show: true });
    }catch(error){
      console.log(error);
    }
  }
}
