<div class="row">
  <div class="col-lg-12">
    <div class="card">
      <div class="card-body">
        <app-search (isReset)="onReset()" (isSearch)="onSearch($event)" [isFormSrc]="showFormAdd"></app-search>
        <div class="row">
          <div class="col-lg-12">
            <div class="table-responsive">
              <table class="table table-hover mb-0">
                <thead class="thead-light">
                <tr>
                  <th class="text-center" [width]="50"></th>
                  <th class="">Surat</th>
                  <th class="">Jumlah Ayat</th>
                  <th class="">Ayat Sudah Diupload</th>
                  <th class="">Kekurangan Ayat</th>
                  <th class="" >Terakhir Diperbarui</th>
                  <th class="text-center" [width]="150">Aksi</th>
                </tr>
                </thead>
                <tbody>
                <ng-container *ngFor="let v of isData; let i = index">
                  <tr>
                    <th class="text-center">{{v.id}}</th>
                    <td class="text-left">{{v.name}}</td>
                    <td class="">{{v.total_ayah}}</td>
                    <td class="">{{v.total_file}}</td>
                    <td class="">{{v.total_ayah - v.ayah}}</td>
                    <td class="">{{v.updatedAt}}</td>
                    <td class="text-center">
                      <a class="btn btn-xs btn-warning text-light mr-5" (click)="onManage(i)" data-bs-toggle="modal"
                         data-bs-target="#formData"> <i class="fa fa-edit"></i> Manage</a>
                    </td>
                  </tr>
                </ng-container>
                </tbody>
              </table><!--end /table-->
            </div>
          </div>
        </div>
        <div class="row mt-3">
          <div class="col-sm-3">
            <div class="pull-left text-uppercase">
              <strong><i class="fe fe-list"></i> TOTAL DATA : {{ isTotalAll }}</strong>
            </div>
          </div>
          <div class="col-sm-6 text-center" *ngIf="isTotalAll > isTotalVw">
            <div class="text-center" (click)="loadMore()">
              <a href="javascript:;" class="btn btn-xs btn-outline-primary">Load more..</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
