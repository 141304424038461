<!-- Left Sidenav -->
<div class="left-sidenav">
  <!-- LOGO -->
  <div class="brand">
    <a href="/" class="logo">
      <img src="assets/images/hatam_logo.svg" alt="logo-small" class="logo-sm">
    </a>
  </div>
  <!--end logo-->
  <div class="menu-content h-100 mt-3" data-simplebar>
    <ul class="metismenu left-sidenav-menu">
      <!-- <li class="menu-label me-3">Menu</li> -->
      <li>
        <a routerLink="/dashboard" [ngClass]="{'active mm-active': active == 'dashboard'}"><i class="align-self-center menu-icon " data-feather="grid"></i><span>Dashboard</span></a>
      </li>
      <li>
        <a href="javascript: void(0);" [ngClass]="{'active mm-active': active == 'audioSurah' || active == 'audioFile' }"><i data-feather="speaker" class="align-self-center menu-icon"></i><span>Audio</span><span class="menu-arrow"><i class="mdi mdi-chevron-right"></i></span></a>
        <ul class="nav-second-level" aria-expanded="false">
          <li class="nav-item"><a class="nav-link" routerLink="/audio-surah"><i data-feather="book-open" class="align-self-center menu-icon"></i>Surat</a></li>
          <li class="nav-item"><a class="nav-link" routerLink="/audio-file"><i data-feather="file" class="align-self-center menu-icon"></i>File</a></li>
        </ul>
      </li>
      <li>
        <a routerLink="/user" [ngClass]="{'active mm-active': active == 'user'}"><i class="align-self-center menu-icon " data-feather="users"></i><span>User</span></a>
      </li>
      <li>
        <a routerLink="/complaint" [ngClass]="{'active mm-active': active == 'pengaduan'}"><i class="align-self-center menu-icon " data-feather="alert-octagon"></i><span>Pengaduan</span></a>
      </li>
      <li>
        <a href="javascript: void(0);" [ngClass]="{'active mm-active': active == 'voucherList' || active == 'voucherBackground'}"><i data-feather="credit-card" class="align-self-center menu-icon"></i><span>Voucher</span><span class="menu-arrow"><i class="mdi mdi-chevron-right"></i></span></a>
        <ul class="nav-second-level" aria-expanded="false">
          <li class="nav-item"><a class="nav-link" routerLink="/voucher-list"><i data-feather="list" class="align-self-center menu-icon"></i>List</a></li>
          <li class="nav-item"><a class="nav-link" routerLink="/voucher-background"><i data-feather="crop" class="align-self-center menu-icon"></i>Background</a></li>
        </ul>
      </li>
      <li>
        <a href="javascript: void(0);" [ngClass]="{'active mm-active': active == 'subscribeVoucher' || active == 'subscribeStore' }"><i data-feather="tag" class="align-self-center menu-icon"></i><span>Berlangganan</span><span class="menu-arrow"><i class="mdi mdi-chevron-right"></i></span></a>
        <ul class="nav-second-level" aria-expanded="false">
            <li class="nav-item"><a class="nav-link" routerLink="/subscribe-voucher"><i data-feather="credit-card" class="align-self-center menu-icon"></i>Voucher</a></li>
            <li class="nav-item"><a class="nav-link" routerLink="/subscribe-store"><i data-feather="shopping-bag" class="align-self-center menu-icon"></i>Store</a></li>
        </ul>
      </li>
      <li>
        <a routerLink="/sahamqu" [ngClass]="{'active mm-active': active == 'sahamqu'}"><i class="align-self-center menu-icon" data-feather="heart"></i><span>SahamQu</span></a>
      </li>
      <li>
        <a routerLink="/playlist" [ngClass]="{'active mm-active': active == 'playlist'}"><i class="align-self-center menu-icon" data-feather="music"></i><span>Playlist</span></a>
      </li>
<!--      <li>-->
<!--        <a routerLink="/setoran" [ngClass]="{'active mm-active': active == 'setoran'}"><i class="align-self-center menu-icon" data-feather="mic"></i><span>Setoran</span></a>-->
<!--      </li>-->
<!--      <li>-->
<!--        <a href="javascript: void(0);" [ngClass]="{'active mm-active': active == 'grupNgaji' || active == 'guru' || active == 'guruPostingan' }" ><i data-feather="award" class="align-self-center menu-icon"></i><span>Kelas Ngaji</span><span class="menu-arrow"><i class="mdi mdi-chevron-right"></i></span></a>-->
<!--        <ul class="nav-second-level" aria-expanded="false">-->
<!--          <li>-->
<!--            <a href="javascript: void(0);"><i class="ti-control-record"></i>Guru <span class="menu-arrow left-has-menu"><i class="mdi mdi-chevron-right"></i></span></a>-->
<!--            <ul class="nav-second-level" aria-expanded="false">-->
<!--              <li><a routerLink="/guru">Approval</a></li>-->
<!--              <li><a routerLink="/postingan">Postingan</a></li>-->
<!--            </ul>-->
<!--          </li>-->
<!--          <li><a routerLink="/grup"><i class="ti-control-record"></i>Grup</a></li>-->
<!--           <li>-->
<!--            <a href="javascript: void(0);"><i class="ti-control-record"></i>Anggota <span class="menu-arrow left-has-menu"><i class="mdi mdi-chevron-right"></i></span></a>-->
<!--            <ul class="nav-second-level" aria-expanded="false">-->
<!--                <li><a routerLink="#">Pembayaran</a></li>-->
<!--            </ul>-->
<!--          </li>-->
<!--        </ul>-->
<!--      </li>-->
<!--      <li>-->
<!--        <a routerLink="/monitoring" [ngClass]="{'active mm-active': active == 'monitoring'}"><i class="align-self-center menu-icon" data-feather="monitor"></i><span>Monitoring</span></a>-->
<!--      </li>-->
<!--      <li>-->
<!--        <a routerLink="/tilawah" [ngClass]="{'active mm-active': active == 'tilawah'}"><i class="align-self-center menu-icon" data-feather="speaker"></i><span>Tilawah</span></a>-->
<!--      </li>-->
      <li>
        <a href="javascript: void(0);" [ngClass]="{'active mm-active': active == 'contentFeed' || active == 'contentVideo' }"><i data-feather="tv" class="align-self-center menu-icon"></i><span>Konten</span><span class="menu-arrow"><i class="mdi mdi-chevron-right"></i></span></a>
        <ul class="nav-second-level" aria-expanded="false">
          <li class="nav-item"><a class="nav-link" routerLink="/content-feed"><i data-feather="instagram" class="align-self-center menu-icon"></i>Feed</a></li>
          <li class="nav-item"><a class="nav-link" routerLink="/content-video"><i data-feather="youtube" class="align-self-center menu-icon"></i>Video</a></li>
        </ul>
      </li>
      <li>
        <a routerLink="/notification" [ngClass]="{'active mm-active': active == 'notification'}"><i class="align-self-center menu-icon" data-feather="send"></i><span>Notifikasi</span></a>
      </li>
      <li>
        <a routerLink="/faq" [ngClass]="{'active mm-active': active == 'faq'}"><i class="align-self-center menu-icon" data-feather="message-circle"></i><span>FAQ</span></a>
      </li>
<!--      <li>-->
<!--        <a routerLink="/konfigurasi" [ngClass]="{'active mm-active': active == 'konfigurasi'}"><i class="align-self-center menu-icon" data-feather="tool"></i><span>Konfigurasi</span></a>-->
<!--      </li>-->
      <li>
        <a routerLink="/administrator" [ngClass]="{'active mm-active': active == 'administrator'}"><i class="align-self-center menu-icon" data-feather="user-plus"></i><span>Admin</span></a>
      </li>
      <!-- <li>
        <a routerLink="#"><i class="align-self-center menu-icon" data-feather="user-plus"></i><span>Staff</span></a>
      </li> -->
      <!-- <li>
        <a href="javascript: void(0);"> <i class="align-self-center menu-icon" data-feather="package"></i><span>Produk & Transaksi</span><span class="menu-arrow"><i class="mdi mdi-chevron-right"></i></span></a>
        <ul class="nav-second-level" aria-expanded="false">
          <li class="nav-item"><a class="nav-link" routerLink="#"><i class="ti-control-record"></i>Data Transaksi</a></li>
          <li class="nav-item"><a class="nav-link" routerLink="#"><i class="ti-control-record"></i>Data Produk</a></li>
        </ul>
      </li> -->
      <!-- <li>
        <a href="javascript: void(0);"> <i class="align-self-center menu-icon" data-feather="clipboard"></i><span>Berita/Profil/Features</span><span class="menu-arrow"><i class="mdi mdi-chevron-right"></i></span></a>
        <ul class="nav-second-level" aria-expanded="false">
          <li class="nav-item"><a class="nav-link" routerLink="#"><i class="ti-control-record"></i>Data Berita/Profil</a></li>
          <li class="nav-item"><a class="nav-link" routerLink="#"><i class="ti-control-record"></i>Kategori Berita/profil</a></li>
        </ul>
      </li> -->
      <!-- <li>
        <a href="javascript: void(0);"> <i class="align-self-center menu-icon" data-feather="video"></i><span>Galeri & Video</span><span class="menu-arrow"><i class="mdi mdi-chevron-right"></i></span></a>
        <ul class="nav-second-level" aria-expanded="false">
          <li class="nav-item"><a class="nav-link" routerLink="#"><i class="ti-control-record"></i>Kategori Galeri</a></li>
          <li class="nav-item"><a class="nav-link" routerLink="#"><i class="ti-control-record"></i>Data Galeri</a></li>
          <li class="nav-item"><a class="nav-link" routerLink="#"><i class="ti-control-record"></i>Data Video</a></li>
        </ul>
      </li> -->
      <!-- <li>
        <a routerLink="/administrator" [ngClass]="{'active mm-active': active == 'administrator'}"><i class="align-self-center menu-icon" data-feather="user"></i><span>Administrator</span></a>
      </li> -->
      <!-- <li>
        <a href="javascript: void(0);"> <i class="align-self-center menu-icon" data-feather="tool"></i><span>Configuration</span><span class="menu-arrow"><i class="mdi mdi-chevron-right"></i></span></a>
        <ul class="nav-second-level" aria-expanded="false">
          <li class="nav-item"><a class="nav-link" routerLink="#"><i class="ti-control-record"></i>Konfigurasi Umum</a></li>
          <li class="nav-item"><a class="nav-link" routerLink="#"><i class="ti-control-record"></i>Logo Website</a></li>
          <li class="nav-item"><a class="nav-link" routerLink="#"><i class="ti-control-record"></i>Icon Website</a></li>
          <li class="nav-item"><a class="nav-link" routerLink="#"><i class="ti-control-record"></i>Quote Website</a></li>
          <li class="nav-item"><a class="nav-link" routerLink="#"><i class="ti-control-record"></i>Data Rekening Pembayaran</a></li>
          <li class="nav-item"><a class="nav-link" routerLink="#"><i class="ti-control-record"></i>Re-captcha Key</a></li>
        </ul>
      </li> -->
    </ul>
  </div>
</div>
<!-- end left-sidenav-->
