import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormArray, FormControl, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { AuthenticationService } from '../../services/auth/authentication.service';
import { ApiService } from '../../services/api/api.service';
import { FormatService } from '../../services/format/format.service';

declare const $:any;

@Component({
  selector: 'app-konfigurasi',
  templateUrl: './konfigurasi.component.html',
  styleUrls: ['./konfigurasi.component.css']
})
export class KonfigurasiComponent implements OnInit {

  isForm : any;
  isPlatform : any = [{'id':'ANDROID','text':'ANDROID'},{'id':'IOS','text':'IOS'}];
  isForce : any = [{'id':1,'text':'true'},{'id':0,'text':'false'}];
  isMaintenance : any = [{'id':1,'text':'true'},{'id':0,'text':'false'}];
  constructor(
    private fb: FormBuilder,
    private toastr: ToastrService,
    private authService: AuthenticationService,
    private apiService: ApiService,
    private formatService: FormatService,
  ) { }

  ngOnInit(): void {
    this.resetForm();
    this.getData();
  }
  async resetForm(){
    this.isForm = this.fb.group({
      id : new FormControl(''),
      admin_id : new FormControl(this.authService.getSession().id, [Validators.required]),
      platform : new FormControl('', [Validators.required]),
      version_code : new FormControl('', [Validators.required]),
      version_name : new FormControl('', [Validators.required]),
      is_force_update : new FormControl('', [Validators.required]),
      is_maintenance : new FormControl('', [Validators.required])
    })
  }
  async getData(){
    try{
      this.apiService.methodGet(`configuration`,this.authService.getToken()).subscribe((response)=>{
        if(response.status == 200) { 
          (response.total ? response.data.forEach((element: any) => { 
            const dataEdit: any = Object.assign({}, element);
            this.isForm.patchValue(dataEdit);
          }) : false);
        }
      });
    }catch(error){
      console.log(error);
    }
  }
  async onSubmit(){
    try{
      if(this.isForm.valid){
        await this.formatService.alertBeforeDelete().then(async (result) => {
          if (result.value) {
            this.apiService.methodPost(`configuration`, this.isForm.value, this.authService.getToken()).subscribe(async(response)=>{
              if(response.status == 200){
                $('#formData').modal('hide');
                await this.toastr.success(`Berhasil disimpan`);
                await this.resetForm();
                await this.getData();
              }
            });
          }
        });
      }
    }catch(error){

    }
  }

}
