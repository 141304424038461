import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { FormatService } from '../../services/format/format.service';
import { ApiService } from '../../services/api/api.service';
import configJson from '../../../assets/config.json';

const SESSION = 'hatam-app';
const IS_API = configJson.apiUrl;

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  isAuthenticated: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  isSession: any = null;

  constructor(
    private http: HttpClient,
    private formatService: FormatService,
    private apiService: ApiService,
    private toastr: ToastrService,
  ) {
    this.loadSession();
  }

  loadSession() {
    try{
      const sessionData = localStorage.getItem(btoa(btoa(SESSION)));
      if (sessionData) {
        this.isSession = atob(atob(sessionData));
        this.isAuthenticated.next(true);
      } else {
        this.isAuthenticated.next(false);
      }
    }catch(error){
      console.log(error);
    }
  }
  getSession(){
    try{
      const sessionData = localStorage.getItem(btoa(btoa(SESSION)));
      if (sessionData) {
        this.isSession = atob(atob(sessionData));
        return JSON.parse(this.isSession);
      } else {
        return {};
      }
    }catch(error){
      console.log(error);
    }
  }
  sessionCek(){
    const sessionData = localStorage.getItem(btoa(btoa(SESSION)));
    if (sessionData) {
      return true;
    } else {
      return false;
    }
  }
  getToken(){
    try{
      const sessionData = localStorage.getItem(btoa(btoa(SESSION)));
      if (sessionData) {
        let token = JSON.parse(atob(atob(sessionData)));
        return token.token;
      } else {
        return {};
      }
    }catch(error){
      console.log(error);
    }
  }
  
  login(credentials: any): Observable<any> {
    this.formatService.blockToggle();
    return this.http.post(`${IS_API}login`, credentials).pipe(
      map((data: any) => {
        this.formatService.blockToggle(false);
        if(data.status != 200){
          return this.toastr.error(`Username/Password tidak ditemukan!`);
        }else{
          this.toastr.success(`Berhasil login`);
          localStorage.setItem(btoa(btoa(SESSION)), btoa(btoa(JSON.stringify(data.data))));
          return data;
        }
      }),
      tap(_ => {
        this.isAuthenticated.next(true)
      }),
      catchError((error) => {
        this.formatService.blockToggle(false);
        this.toastr.error(`Username/Password tidak ditemukan!`);
        return error.error;
      })
    );
  }

  logout() {
    // this.apiService.methodGet('authLogout', this.isToken).subscribe(
    //   async (res: any) => {
    //     if (res.status.statusCode == 200) {
          this.isAuthenticated.next(false);
          localStorage.clear();
    //     }
    //   },
    //   async (response) => { console.log(response); }
    // );
  }
}