<div *blockUI="'contact-list'">
  <div class="art-bg">
    <img src="assets/images/arts/art1.svg" alt="" class="art-img light-img">
  </div>
<div *ngIf="title != 'Login'">
  <app-sidebar></app-sidebar>
  <div class="page-wrapper">
    <app-navbar></app-navbar>
    <!-- Page Content-->
    <div class="page-content">
        <div class="container-fluid">
            <!-- Page-Title -->
            <app-title></app-title>
            <!-- end page title end breadcrumb -->
            <router-outlet></router-outlet>
        </div><!-- container -->
        <app-footer></app-footer>
    </div>
    <!-- end page content -->
  </div>
  <!-- end page-wrapper -->
</div>
<div *ngIf="title == 'Login'">
  <app-login></app-login>
</div>
</div>