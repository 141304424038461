<div class="row">
  <div class="col-lg-12">
    <div class="card">
      <div class="card-body">
        <app-search (isFormAdd)="onShowAdd()" (isReset)="onReset()" (isSearch)="onSearch($event)" [isFormSrc]="showFormAdd"></app-search>
        <div class="row">
          <div class="col-lg-12">
            <div class="table-responsive">
              <table class="table table-hover mb-0">
                <thead class="thead-light">
                  <tr>
                      <th class="text-center" [width]="50">No</th>
                      <th class="text-center" [width]="150">Action</th>
                      <th class="" >Nama</th>
                      <th class="" >Email</th>
                      <!-- <th class="" >Level akses</th> -->
                      <th class="" >Username</th>
                  </tr>
                </thead>
                <tbody>
                  <ng-container *ngFor="let v of isData; let i = index">
                    <tr>
                      <th class="text-center" >{{i + 1}}</th>
                      <td class="text-center" >
                        <a class="btn btn-xs btn-warning text-light mr-5" (click)="onEdit(i)" data-bs-toggle="modal" data-bs-target="#formData"> <i class="fa fa-edit"></i> Edit</a>
                        <a class="btn btn-xs btn-danger mr-5" (click)="onDelete(v.id)"> <i class="fa fa-trash"></i> Hapus</a>
                      </td>
                      <td class="" >{{v.name}}</td>
                      <td class="" >{{v.email}}</td>
                      <!-- <td class="text-center" >{{v.level_akses}}</td> -->
                      <td class="" >{{v.username}}</td>
                    </tr>
                  </ng-container>
                </tbody>
              </table><!--end /table-->
            </div>
          </div>
        </div>
        <div class="row mt-3">
          <div class="col-sm-3">
            <div class="pull-left text-uppercase">
              <strong><i class="fe fe-list"></i> TOTAL DATA : {{ isTotalAll }}</strong>
            </div>
          </div>
          <div class="col-sm-6 text-center" *ngIf="isTotalAll > isTotalVw">
            <div class="text-center" (click)="loadMore()">
              <a href="javascript:;" class="btn btn-xs btn-outline-primary" >Load more..</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- FORM -->
<form [formGroup]="isForm" (ngSubmit)="onSubmit()">
  <div class="modal fade" id="formData" aria-labelledby="formDataLabel" aria-hidden="true" data-keyboard="false" data-backdrop="static">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h6 class="modal-title" id="formDataLabel">FORM DATA</h6>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-lg-12">
              <div class="mb-3 row">
                <label for="name" class="col-sm-2 form-label align-self-center mb-lg-0 text-end">Nama *</label>
                <div class="col-sm-8">
                  <input class="form-control" type="text" id="name" placeholder="name" formControlName="name">
                </div>
              </div>
            </div>
            <div class="col-lg-12">
              <div class="mb-3 row">
                <label for="email" class="col-sm-2 form-label align-self-center mb-lg-0 text-end">Email *</label>
                <div class="col-sm-8">
                    <input class="form-control" type="email" id="email" placeholder="Email" formControlName="email">
                </div>
              </div>
            </div>
            <!-- <div class="col-lg-12">
              <div class="mb-3 row">
                <label for="level_akses" class="col-sm-2 form-label align-self-center mb-lg-0 text-end">Level akses *</label>
                <div class="col-sm-8">
                  <ng-select2 formControlName="level_akses" [data]="levelAkses" [placeholder]="'-- Level akses --'"></ng-select2>
                </div>
              </div>
            </div> -->
            <div class="col-lg-12">
              <div class="mb-3 row">
                <label for="username" class="col-sm-2 form-label align-self-center mb-lg-0 text-end">Username *</label>
                <div class="col-sm-8">
                    <input class="form-control" type="text" id="username" placeholder="Username" formControlName="username">
                </div>
              </div>
            </div>
            <div class="col-lg-12">
              <div class="mb-3 row">
                <label for="password" class="col-sm-2 form-label align-self-center mb-lg-0 text-end">Password *</label>
                <div class="col-sm-8">
                    <input class="form-control" type="password" id="password" placeholder="Password" formControlName="password">
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="submit" class="btn btn-soft-primary btn-sm" [disabled]="!isForm.valid" >Simpan</button>
          <button type="button" class="btn btn-soft-danger btn-sm" data-bs-dismiss="modal">Batal</button>
        </div>
      </div>
    </div>
  </div>
</form>