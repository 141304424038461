<div class="row">
  <div class="col-lg-12">
    <div class="card">
      <div class="card-body">
        <app-search (isReset)="onReset()" (isSearch)="onSearch($event)" [isFormSrc]="showFormAdd"></app-search>
        <div class="row">
          <div class="col-lg-12">
            <div class="table-responsive">
              <table class="table table-hover mb-0">
                <thead class="thead-light">
                  <tr>
                      <th class="text-center" [width]="50">No</th>
                      <th class="" >User</th>
                      <th class="" >Judul</th>
                      <th class="" >Awal</th>
                      <th class="" >Akhir</th>
                      <th class="" >Skor Bacaan</th>
                      <th class="" >Skor Irama</th>
                  </tr>
                </thead>
                <tbody>
                  <ng-container *ngFor="let v of isData; let i = index">
                    <tr>
                      <th class="text-center" >{{i + 1}}</th>
                      <td class="" >{{v.user.name}}</td>
                      <td class="" >{{v.title}}</td>
                      <td class="" >
                        {{v.quran_surah.name}}: {{v.begin_ayah}}
                      </td>
                      <td class="" >
                        {{v.quran_surah_end.name}}: {{v.end_ayah}}
                      </td>
                      <td class="">{{v.score_reading}}</td>
                      <td class="">{{v.score_rhythm}}</td>
                    </tr>
                  </ng-container>
                </tbody>
              </table><!--end /table-->
            </div>
          </div>
        </div>
        <div class="row mt-3">
          <div class="col-sm-3">
            <div class="pull-left text-uppercase">
              <strong><i class="fe fe-list"></i> TOTAL DATA : {{ isTotalAll }}</strong>
            </div>
          </div>
          <div class="col-sm-6 text-center" *ngIf="isTotalAll > isTotalVw">
            <div class="text-center" (click)="loadMore()">
              <a href="javascript:;" class="btn btn-xs btn-outline-primary" >Load more..</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
