import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AuthenticationService } from '../../services/auth/authentication.service';
import { ApiService } from '../../services/api/api.service';
import { FormatService } from '../../services/format/format.service';

declare var $: any;

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})
export class NavbarComponent implements OnInit {
  isMe : any;
  constructor(
    private authService: AuthenticationService,
    private apiService: ApiService,
    private formatService: FormatService,
    private toastr: ToastrService,
    private router: Router,
  ) { }

  ngOnInit(): void {
    this.sessionInfo();
  }
  sessionInfo(){
    this.isMe = this.authService.getSession();
  }
  async logout() {
    // $('#Logout').modal('hide');
    await this.formatService.alertBeforeDelete().then(async (result) => {
      if (result.value) {
        this.authService.logout();
        this.router.navigateByUrl('/login', { replaceUrl: true });
      }
    });
  }
}
