import { Component } from '@angular/core';
import { Title } from '@angular/platform-browser';  
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';  
import { filter } from 'rxjs/operators';  

declare const appJs: any;
declare const $: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  jsReady = 0;
  constructor(private router: Router,  
              private activatedRoute: ActivatedRoute,  
              private titleService: Title) {  
  }  
  
  ngOnInit() {  
    this.router.events.pipe(  
        filter(event => event instanceof NavigationEnd),  
      ).subscribe(() => {  
        const rt = this.getChild(this.activatedRoute);  
        rt.data.subscribe(data => {  
          this.titleService.setTitle(data.title)
          if(data.title != "Login" && this.jsReady == 0){
            appJs(); 
            this.jsReady =1;
          }else if(data.title == "Login"){
            this.jsReady = 0;
          }
        });  
      });  
  }  
  get title(): string { return this.titleService.getTitle() }
  getChild(activatedRoute: ActivatedRoute) {  
    if (activatedRoute.firstChild) {  
      return this.getChild(activatedRoute.firstChild);  
    } else {  
      return activatedRoute;  
    }  
  }
}
