<div class="row">
  <div class="col-lg-12">
    <div class="card">
      <div class="card-body">
        <app-search (isReset)="onReset()" (isSearch)="onSearch($event)" [isFormSrc]="showFormAdd"></app-search>
        <div class="row">
          <div class="col-lg-12">
            <div class="table-responsive">
              <table class="table table-hover mb-0">
                <thead class="thead-light">
                  <tr>
                      <th class="text-center" [width]="50">No</th>
                      <th class="text-center" >Aksi</th>
                      <th class="" >Nama</th>
                      <th class="" >Pendidikan Terakhir</th>
                      <th class="" >Institusi</th>
                      <th class="" >Sertifikat</th>
                      <th class="" >Vidio</th>
                      <th class="" >Status</th>
                  </tr>
                </thead>
                <tbody>
                  <ng-container *ngFor="let v of isData; let i = index">
                    <tr>
                      <th class="text-center" >{{i + 1}}</th>
                      <td class="text-center">
                        <a class="btn btn-xs btn-warning text-light mr-5" data-bs-toggle="modal" data-bs-target="#formData"  (click)="onEdit(i)"> <i class="fa fa-edit"></i> Verifikasi</a>
                      </td>
                      <td class="" >{{v.user.name}}</td>
                      <td class="">{{v.last_academic}}</td>
                      <td class="">{{v.institution}}</td>
                      <td class="">
                        <a *ngIf="v.certificate" target="_blank" href="{{v.certificate}}" class="btn btn-xs btn-success"> <i class="fa fa-eye"></i> Lihat</a>
                      </td>
                      <td class="">
                        <a *ngIf="v.video" target="_blank" href="{{v.video}}" class="btn btn-xs btn-success"> <i class="fa fa-eye"></i> Lihat</a>
                      </td>
                      <td class="">
                        <span *ngIf="v.status == '0'" class="badge badge-soft-warning">Sedang Diverifikasi</span>
                        <span *ngIf="v.status == '1'" class="badge badge-soft-success">Lulus Verifikasi</span>
                        <span *ngIf="v.status == '2'" class="badge badge-soft-danger">Verifikasi Ditolak</span>
                      </td>
                    </tr>
                  </ng-container>
                </tbody>
              </table><!--end /table-->
            </div>
          </div>
        </div>
        <div class="row mt-3">
          <div class="col-sm-3">
            <div class="pull-left text-uppercase">
              <strong><i class="fe fe-list"></i> TOTAL DATA : {{ isTotalAll }}</strong>
            </div>
          </div>
          <div class="col-sm-6 text-center" *ngIf="isTotalAll > isTotalVw">
            <div class="text-center" (click)="loadMore()">
              <a href="javascript:;" class="btn btn-xs btn-outline-primary" >Load more..</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- FORM -->
<form [formGroup]="isForm" (ngSubmit)="onSubmit()">
  <div class="modal fade" id="formData" aria-labelledby="formDataLabel" aria-hidden="true" data-keyboard="false" data-backdrop="static">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h6 class="modal-title" id="formDataLabel">FORM DATA</h6>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-lg-12">
              <div class="mb-3 row">
                <label class="col-sm-2 form-label mb-lg-0 text-end">Status Verifikasi *</label>
                <div class="col-sm-8">
                  <ng-select2 formControlName="status" [data]="isStatus" [placeholder]="'-- Pilih Status --'"></ng-select2>
                </div>
              </div>
            </div>
            <div class="col-lg-12">
              <div class="mb-3 row">
                <label class="col-sm-2 form-label mb-lg-0 text-end">Note *</label>
                <div class="col-sm-8">
                  <textarea formControlName="notes" class="form-control" cols="30" rows="10"></textarea>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="submit" class="btn btn-soft-primary btn-md" [disabled]="!isForm.valid" >Simpan</button>
          <button type="button" class="btn btn-soft-danger btn-md" data-bs-dismiss="modal">Batal</button>
        </div>
      </div>
    </div>
  </div>
</form>