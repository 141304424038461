import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {AuthGuardService} from './services/auth/auth-guard.service';

import {AdministratorComponent} from './pages/administrator/administrator.component';
import {AudioFileComponent} from './pages/audio-file/audio-file.component';
import {DashboardComponent} from './pages/dashboard/dashboard.component';
import {FaqComponent} from './pages/faq/faq.component';
import {GrupNgajiComponent} from './pages/grup-ngaji/grup-ngaji.component';
import {GuruPostinganComponent} from './pages/guru-postingan/guru-postingan.component';
import {GuruComponent} from './pages/guru/guru.component';
import {KonfigurasiComponent} from './pages/konfigurasi/konfigurasi.component';
import {LoginComponent} from './pages/login/login.component';
import {MonitoringComponent} from './pages/monitoring/monitoring.component';
import {SubscribeStoreComponent} from './pages/subscribe-store/subscribe-store.component';
import {PlaylistComponent} from './pages/playlist/playlist.component';
import {SahamquComponent} from './pages/sahamqu/sahamqu.component';
import {SetoranComponent} from './pages/setoran/setoran.component';
import {TilawahComponent} from './pages/tilawah/tilawah.component';
import {UserComponent} from './pages/user/user.component';
import {VoucherBackgroudComponent} from './pages/voucher-backgroud/voucher-backgroud.component';
import {VoucherListComponent} from './pages/voucher-list/voucher-list.component';
import {SubscribeVoucherComponent} from './pages/subscribe-voucher/subscribe-voucher.component';
import {ContentFeedComponent} from './pages/content-feed/content-feed.component';
import {ContentVideoComponent} from './pages/content-video/content-video.component';
import {AudioSuratComponent} from './pages/audio-surat/audio-surat.component';
import {ComplaintComponent} from './pages/complaint/complaint.component';

const routes: Routes = [
  {path: 'login/:logout', component: LoginComponent, data: {title: 'Login'}},
  {path: 'login', component: LoginComponent, data: {title: 'Login'}},
  {
    path: 'dashboard',
    component: DashboardComponent,
    data: {menu: 'dashboard', title: 'Dashboard', canActive: []},
    canActivate: [AuthGuardService]
  },
  {
    path: 'audio-surah',
    component: AudioSuratComponent,
    data: {menu: 'audioSurah', title: 'Audio Surat', canActive: []},
    canActivate: [AuthGuardService]
  },
  {
    path: 'audio-file',
    component: AudioFileComponent,
    data: {menu: 'audioFile', title: 'Audio File', canActive: []},
    canActivate: [AuthGuardService]
  },
  {
    path: 'user',
    component: UserComponent,
    data: {menu: 'user', title: 'User', canActive: []},
    canActivate: [AuthGuardService]
  },
  {
    path: 'complaint',
    component: ComplaintComponent,
    data: {menu: 'complaint', title: 'Pengaduan', canActive: []},
    canActivate: [AuthGuardService]
  },
  {
    path: 'voucher-list',
    component: VoucherListComponent,
    data: {menu: 'voucherList', title: 'List Voucher', canActive: []},
    canActivate: [AuthGuardService]
  },
  {
    path: 'voucher-background',
    component: VoucherBackgroudComponent,
    data: {menu: 'voucherBackground', title: 'Background Voucher', canActive: []},
    canActivate: [AuthGuardService]
  },
  {
    path: 'subscribe-voucher',
    component: SubscribeVoucherComponent,
    data: {menu: 'subscribeVoucher', title: 'Berlangganan Voucher', canActive: []},
    canActivate: [AuthGuardService]
  },
  {
    path: 'subscribe-store',
    component: SubscribeStoreComponent,
    data: {menu: 'subscribeStore', title: 'Berlangganan Store', canActive: []},
    canActivate: [AuthGuardService]
  },
  {
    path: 'content-feed',
    component: ContentFeedComponent,
    data: {menu: 'contentFeed', title: 'Konten Feed', canActive: []},
    canActivate: [AuthGuardService]
  },
  {
    path: 'content-video',
    component: ContentVideoComponent,
    data: {menu: 'contentVideo', title: 'Konten Video', canActive: []},
    canActivate: [AuthGuardService]
  },
  {
    path: 'playlist',
    component: PlaylistComponent,
    data: {menu: 'playlist', title: 'Playlist', canActive: []},
    canActivate: [AuthGuardService]
  },
  {
    path: 'setoran',
    component: SetoranComponent,
    data: {menu: 'setoran', title: 'Setoran', canActive: []},
    canActivate: [AuthGuardService]
  },
  {
    path: 'grup',
    component: GrupNgajiComponent,
    data: {menu: 'grupNgaji', title: 'Grup', canActive: []},
    canActivate: [AuthGuardService]
  },
  {
    path: 'guru',
    component: GuruComponent,
    data: {menu: 'guru', title: 'Approval Guru', canActive: []},
    canActivate: [AuthGuardService]
  },
  {
    path: 'postingan',
    component: GuruPostinganComponent,
    data: {menu: 'guruPostingan', title: 'Postingan Guru', canActive: []},
    canActivate: [AuthGuardService]
  },
  {
    path: 'monitoring',
    component: MonitoringComponent,
    data: {menu: 'monitoring', title: 'Monitoring', canActive: []},
    canActivate: [AuthGuardService]
  },
  {
    path: 'tilawah',
    component: TilawahComponent,
    data: {menu: 'tilawah', title: 'Tilawah', canActive: []},
    canActivate: [AuthGuardService]
  },
  {
    path: 'sahamqu',
    component: SahamquComponent,
    data: {menu: 'sahamqu', title: 'SahamQu', canActive: []},
    canActivate: [AuthGuardService]
  },
  {
    path: 'konfigurasi',
    component: KonfigurasiComponent,
    data: {menu: 'konfigurasi', title: 'Konfigurasi', canActive: []},
    canActivate: [AuthGuardService]
  },
  {
    path: 'faq',
    component: FaqComponent,
    data: {menu: 'faq', title: 'FAQ', canActive: []},
    canActivate: [AuthGuardService]
  },
  {
    path: 'administrator',
    component: AdministratorComponent,
    data: {menu: 'administrator', title: 'Administrator', canActive: []},
    canActivate: [AuthGuardService]
  },

  {path: '', redirectTo: '/dashboard', pathMatch: 'full'},
  {
    path: '**',
    component: DashboardComponent,
    data: {menu: 'dashboard', title: 'Dashboard', canActive: []},
    canActivate: [AuthGuardService]
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
