<div class="row">
  <div class="col-sm-12">
      <div class="page-title-box">
          <div class="row">
              <div class="col">
                  <h4 class="page-title">{{title}}</h4>
                  <ol class="breadcrumb">
                      <li class="breadcrumb-item"><a href="javascript:void(0);">Home</a></li>
                      <!-- <li class="breadcrumb-item"><a href="javascript:void(0);">Dashboard</a></li> -->
                      <li class="breadcrumb-item active">{{title}}</li>
                  </ol>
              </div>
          </div><!--end row-->                                                              
      </div><!--end page-title-box-->
  </div><!--end col-->
</div><!--end row-->