import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, FormArray, FormControl, Validators} from '@angular/forms';
import {ToastrService} from 'ngx-toastr';
import {AuthenticationService} from '../../services/auth/authentication.service';
import {ApiService} from '../../services/api/api.service';
import {FormatService} from '../../services/format/format.service';
import * as moment from 'moment';
import {ActivatedRoute, Router} from '@angular/router';
import Swal from 'sweetalert2';

declare const $: any;

@Component({
  selector: 'app-complaint',
  templateUrl: './complaint.component.html',
  styleUrls: ['./complaint.component.css']
})
export class ComplaintComponent implements OnInit {

  showFormAdd: any = {isFormSearch: 4, isType: 'add', };
  isForm: any;
  isDetailData: any;
  isData: any = [];
  isOffset = 0;
  isTotalVw = 0;
  isTotalAll = 0;

  isKeyword = '';
  isStatus = '';

  attachment: any = [];
  dataSurah: any = [];

  isLoading = false;
  disableSelection = false;

  constructor(
    private fb: FormBuilder,
    private toastr: ToastrService,
    private authService: AuthenticationService,
    private apiService: ApiService,
    private formatService: FormatService,
    private route: ActivatedRoute
  ) {

  }

  ngOnInit(): void {
    this.getSurah();
    this.resetForm();
    this.getData();
    this.route.queryParams
      .subscribe(params => {
          if (params.id) {
            this.disableSelection = true;
          }
        }
      );
  }

  toggleCollapse(index: number) {
    this.isData[index].isCollapsed = !this.isData[index].isCollapsed;
  }

  async resetForm() {
    this.isForm = this.fb.group({
      id: new FormControl(''),
      surah: new FormControl('', [Validators.required]),
      ayah: new FormControl('', [Validators.required]),
      file: new FormControl('', [Validators.required]),
    });
  }

  async getSurah() {
    try {
      this.apiService.methodGet(`surah`, this.authService.getToken()).subscribe((response) => {
        if (response.status == 200) {
          this.dataSurah = response.data;
        }
      });
    } catch (error) {
      console.log(error);
    }
  }

  async onReset() {
    this.isData = await [];
    this.isOffset = await 0;
    this.isTotalVw = await 0;
    this.isTotalAll = await 0;
    this.isKeyword = await '';
    this.isStatus = await '';
    await this.resetForm();
    await this.getData();
  }

  async onSearch($event: any) {
    this.isData = await [];
    this.isOffset = await 0;
    this.isTotalVw = await 0;
    this.isTotalAll = await 0;
    this.isKeyword = await ($event.keyword ? $event.keyword : '');
    this.isStatus = await ($event.status ? $event.status : '');
    this.getData();
  }

  async onDelete(id: number) {
    try {
      await this.formatService.alertBeforeDelete().then(async (result) => {
        if (result.value) {
          this.apiService.methodDelete(`audio/${id}`, this.authService.getToken()).subscribe(async (response) => {
            if (response.status == 200) {
              await this.toastr.success(`Berhasil dihapus`);
              this.onReset();
            }
          });
        }
      });
    } catch (error) {
      console.log(error);
    }
  }

  async onUpdateStatus(id: number, status: string) {
    try {
      await this.alertBeforeUpdateStatus(status).then(async (result) => {
        if (result.value) {
          this.apiService.methodPut(`complaint/${id}`, {
            status
          }, this.authService.getToken()).subscribe(async (response) => {
            if (response.status == 200) {
              await this.toastr.success(`Berhasil diupdate`);
              this.onReset();
            }
          });
        }
      });
    } catch (error) {
      console.log(error);
    }
  }

  async getData() {
    const searchData = {keyword: this.isKeyword, status: this.isStatus};
    try {
      this.apiService.methodGet(`complaint/${this.isOffset}/${encodeURIComponent(JSON.stringify(searchData))}`, this.authService.getToken()).subscribe((response) => {
        if (response.status === 200) {
          (response.total ? response.data.forEach((element: any) => {
            element.user = element.user.name;
            if (element.begin_surah === element.end_surah){
              element.name = element.quran_begin_surah.name + ' ' + element.begin_ayah  + ' - ' + element.end_ayah;
            }else{
              // tslint:disable-next-line:max-line-length
              element.name = element.quran_begin_surah.name + ' ' + element.begin_ayah + ' - ' + element.quran_end_surah.name + ' ' + element.end_ayah;
            }
            if (element.category === 'lack_of_verses'){
             element.notes = 'Kekurangan Ayat';
           }else if (element.category === 'excess_verses'){
             element.notes = 'Kekurangan Ayat';
           }else if (element.category === 'wrong_pronunciation'){
            element.notes = 'Salah Pengucapan';
          }
            element.createdAt = moment(element.createdAt).locale('id').format('D MMMM YYYY HH:mm');
            element.audio.forEach((audio: any) => {
              audio.url = `${response.directoryFile}audio/${audio.file}`;
            });
            this.isData.push(element);
          }) : false);
          this.isTotalVw = this.isData.length;
          this.isTotalAll = response.total;
        }
      });
    } catch (error) {
      console.log(error);
    }
  }

  async loadMore() {
    this.isOffset = await this.isTotalVw;
    await this.getData();
  }

  async onShowAdd() {
    await this.resetForm();
    $('#formData').modal({backdrop: 'static', keyboard: false});
    this.route.queryParams
      .subscribe(params => {
          if (params.id) {
            this.disableSelection = true;
            this.isForm.patchValue({surah: params.id});
          }
        }
      );
  }

  async onSubmit() {
    try {
      if (this.isForm.valid) {
        this.isLoading = true;
        const formValue = await new FormData();
        formValue.append('id', this.isForm.value.id);
        formValue.append('admin_id', this.authService.getSession().id);
        formValue.append('surah', this.isForm.value.surah);
        formValue.append('ayah', this.isForm.value.ayah);
        formValue.append('file', this.isForm.value.file);
        console.log(formValue);
        if (this.isForm.value.id) {
          this.apiService.methodPutWithFormdata(`audio/${this.isForm.value.id}`, formValue, this.authService.getToken()).subscribe(async (response) => {
            $('#formData').modal('hide');
            this.isLoading = false;
            if (response.status == 200) {
              await this.toastr.success(`Berhasil diupdate`);
              await this.onReset();
            } else {
              await this.toastr.error(response.message || 'Gagal memuat data');
            }
          });
        } else {
          this.apiService.methodPostWithFormdata(`audio`, formValue, this.authService.getToken()).subscribe(async (response) => {
            $('#formData').modal('hide');
            this.isLoading = false;
            if (response.status == 200) {
              await this.toastr.success(`Berhasil disimpan`);
              await this.onReset();
            } else {
              await this.toastr.error(response.message || 'Gagal memuat data');
            }
          });
        }
      }
    } catch (error) {

    }
  }

  async onEdit(i: number, j: number) {
    try {
      this.resetForm();
      const dataEdit: any = Object.assign({}, this.isData[i].audio[j]);
      await this.isForm.patchValue(dataEdit);
      this.isForm.controls.file.clearValidators();
      this.isForm.controls.file.updateValueAndValidity();
      await $('#formData').modal({backdrop: 'static', keyboard: true, show: true});
    } catch (error) {
      console.log(error);
    }
  }

  async onFileSelect(e, n) {
    if (e.target.files.length > 0) {
      this.attachment = e.target.files[0];
      this.isForm.value[n] = this.attachment;
    }
  }

  alertBeforeUpdateStatus(status: string){
    let title = 'Ubah status menjadi Belum Diperbaiki?';
    if (status === 'approved'){
      title = 'Apakah Anda yakin Sudah Diperbaiki?';
    }
    return Swal.fire({
      title: title,
      icon: 'warning',
      allowOutsideClick: false,
      backdrop: true,
      showCancelButton: true,
      confirmButtonText: '<i class="ft-check"></i>&nbsp;Yakin!',
      cancelButtonText: '<i class="ft-x"></i>&nbsp;Tidak',
    });
  }

}
