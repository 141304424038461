<div class="row">
  <div class="col-lg-12">
    <div class="card">
      <div class="card-body">
        <app-search (isFormAdd)="onShowAdd()" (isReset)="onReset()" (isSearch)="onSearch($event)"
                    [isFormSrc]="showFormAdd"></app-search>
        <div class="row">
          <div class="col-lg-12">
            <div class="table-responsive">
              <table class="table table-hover mb-0">
                <thead class="thead-light">
                <tr>
                  <th class="text-center" [width]="50">No</th>
                  <th class="">Surat</th>
                  <th class="">Ayat</th>
                  <th class="">File</th>
                  <th class="">Terakhir Diperbarui</th>
                  <th class="text-center" [width]="150">Aksi</th>
                </tr>
                </thead>
                <tbody>
                <ng-container *ngFor="let v of isData; let i = index">
                  <tr>
                    <th class="text-center">{{i + 1}}</th>
                    <td class="text-left">{{v.quran_surah.name}}</td>
                    <td class="">{{v.ayah}}</td>
                    <td class="">
                        <span *ngIf="v.file">
                          <a href="{{v.url}}" target="_blank" class="btn btn-xs btn-success"> <i class="fa fa-play"></i> Play</a> {{v.file}}
                        </span>
                      <span *ngIf="!v.file"></span>
                    </td>
                    <td class="text-left">{{v.updatedAt}}</td>
                    <td class="text-center">
                      <a class="btn btn-xs btn-warning text-light mr-5" (click)="onEdit(i)" data-bs-toggle="modal"
                         data-bs-target="#formData"> <i class="fa fa-edit"></i> Edit</a>
                      <a class="btn btn-xs btn-danger mr-5" (click)="onDelete(v.id)"> <i class="fa fa-trash"></i> Hapus</a>
                    </td>
                  </tr>
                </ng-container>
                </tbody>
              </table><!--end /table-->
            </div>
          </div>
        </div>
        <div class="row mt-3">
          <div class="col-sm-3">
            <div class="pull-left text-uppercase">
              <strong><i class="fe fe-list"></i> TOTAL DATA : {{ isTotalAll }}</strong>
            </div>
          </div>
          <div class="col-sm-6 text-center" *ngIf="isTotalAll > isTotalVw">
            <div class="text-center" (click)="loadMore()">
              <a href="javascript:;" class="btn btn-xs btn-outline-primary">Load more..</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- FORM -->
<form [formGroup]="isForm" (ngSubmit)="onSubmit()">
  <div class="modal fade" id="formData" aria-labelledby="formDataLabel" aria-hidden="true" data-keyboard="false"
       data-backdrop="static">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h6 class="modal-title" id="formDataLabel">FORM DATA</h6>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-lg-12">
              <div class="mb-3 row">
                <label for="nama_surah" class="col-sm-2 form-label align-self-center mb-lg-0 text-end">Surat *</label>
                <div class="col-sm-8">
                  <ng-select2 id="nama_surah" formControlName="surah" [data]="dataSurah"
                              [placeholder]="'-- Pilih Surat --'" [disabled]="disableSelection"></ng-select2>
                </div>
              </div>
            </div>
            <div class="col-lg-12">
              <div class="mb-3 row">
                <label for="ayah" class="col-sm-2 form-label align-self-center mb-lg-0 text-end">Ayat *</label>
                <div class="col-sm-8">
                  <input class="form-control" type="number" id="ayah" placeholder="-- Nomor Ayat --"
                         formControlName="ayah">
                </div>
              </div>
            </div>
            <div class="col-lg-12">
              <div class="mb-3 row">
                <label for="file" class="col-sm-2 form-label align-self-center mb-lg-0 text-end">File *</label>
                <div class="col-sm-8">
                  <input class="form-control" type="file" id="file" placeholder="File surah" formControlName="file"
                         (change)="onFileSelect($event,'file')">
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="submit" class="btn btn-soft-primary btn-md" [disabled]="!isForm.valid"> <i class="fa fa-spinner fa-spin" *ngIf="isLoading"></i> Simpan</button>
          <button type="button" class="btn btn-soft-danger btn-md" data-bs-dismiss="modal">Batal</button>
        </div>
      </div>
    </div>
  </div>
</form>
