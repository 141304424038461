import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthenticationService } from '../../services/auth/authentication.service';
import { ApiService } from '../../services/api/api.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  credentials: any;

  constructor(
    private authService: AuthenticationService,
    private router: Router,
    private apiService: ApiService,
  ) { }

  ngOnInit(): void {
    this.sessionCek();
    this.credentials = new FormGroup({
      username: new FormControl('', [Validators.required]),
      password: new FormControl('', [Validators.required]),
    });
  }

  async sessionCek(){
    let cek = await this.authService.sessionCek();
    if(cek){
      await this.router.navigateByUrl('/dashboard', { replaceUrl: true }).then(() => {
        window.location.reload();
      });
    }
  }

  async login() {
    if (this.credentials.valid) {
      this.authService.login(this.credentials.value).subscribe(
        async (res) => {
          if(res.status == 200){
            await this.authService.loadSession();
            await this.router.navigateByUrl('/dashboard', { replaceUrl: true }).then(() => {
              window.location.reload();
            });
          }else{
            this.router.navigateByUrl('/login', { replaceUrl: true })
          }
        },
        async (res) => {
          this.router.navigateByUrl('/login', { replaceUrl: true })
        }
      );
    }
  }
}
