import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {ToastrModule} from 'ngx-toastr';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {NgSelect2Module} from 'ng-select2';
import {HttpClientModule} from '@angular/common/http';
import {BlockUIModule} from 'ng-block-ui';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {FooterComponent} from './parts/footer/footer.component';
import {NavbarComponent} from './parts/navbar/navbar.component';
import {SidebarComponent} from './parts/sidebar/sidebar.component';
import {TitleComponent} from './parts/title/title.component';
import {DashboardComponent} from './pages/dashboard/dashboard.component';
import {LoginComponent} from './pages/login/login.component';
import {UserComponent} from './pages/user/user.component';
import {AdministratorComponent} from './pages/administrator/administrator.component';
import {PlaylistComponent} from './pages/playlist/playlist.component';
import {SetoranComponent} from './pages/setoran/setoran.component';
import {MonitoringComponent} from './pages/monitoring/monitoring.component';
import {TilawahComponent} from './pages/tilawah/tilawah.component';
import {SahamquComponent} from './pages/sahamqu/sahamqu.component';
import {KonfigurasiComponent} from './pages/konfigurasi/konfigurasi.component';
import {FaqComponent} from './pages/faq/faq.component';
import {AudioFileComponent} from './pages/audio-file/audio-file.component';
import {VoucherListComponent} from './pages/voucher-list/voucher-list.component';
import {VoucherBackgroudComponent} from './pages/voucher-backgroud/voucher-backgroud.component';
import {SubscribeVoucherComponent} from './pages/subscribe-voucher/subscribe-voucher.component';
import {SubscribeStoreComponent} from './pages/subscribe-store/subscribe-store.component';
import {GrupNgajiComponent} from './pages/grup-ngaji/grup-ngaji.component';
import {GuruComponent} from './pages/guru/guru.component';
import {GuruPostinganComponent} from './pages/guru-postingan/guru-postingan.component';
import {SearchComponent} from './parts/search/search.component';
import {BlockComponent} from './parts/block/block.component';
import {ContentFeedComponent} from "./pages/content-feed/content-feed.component";
import {ContentVideoComponent} from "./pages/content-video/content-video.component";
import {AudioSuratComponent} from "./pages/audio-surat/audio-surat.component";
import {ComplaintComponent} from "./pages/complaint/complaint.component";

@NgModule({
  declarations: [
    AppComponent,
    FooterComponent,
    NavbarComponent,
    SidebarComponent,
    TitleComponent,
    DashboardComponent,
    LoginComponent,
    UserComponent,
    AdministratorComponent,
    PlaylistComponent,
    SetoranComponent,
    MonitoringComponent,
    TilawahComponent,
    SahamquComponent,
    KonfigurasiComponent,
    FaqComponent,
    AudioFileComponent,
    AudioSuratComponent,
    VoucherListComponent,
    VoucherBackgroudComponent,
    SubscribeVoucherComponent,
    SubscribeStoreComponent,
    GrupNgajiComponent,
    GuruComponent,
    GuruPostinganComponent,
    SearchComponent,
    BlockComponent,
    ContentFeedComponent,
    ContentVideoComponent,
    ComplaintComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    CommonModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot({
      positionClass: 'toast-top-right',
      preventDuplicates: true,
    }),
    BlockUIModule.forRoot({
      template: BlockComponent
    }),
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    NgSelect2Module
  ],
  entryComponents: [
    BlockComponent
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule {
}
