<div class="row">
  <div class="col-lg-12">
    <div class="card">
      <form [formGroup]="isForm" (ngSubmit)="onSubmit()">
        <div class="card-body">
          <div class="row">
            <div class="col-lg-12">
              <div class="mb-3 row">
                <label for="platform" class="col-sm-2 form-label align-self-center mb-lg-0 text-end">Platform *</label>
                <div class="col-sm-5">
                  <ng-select2 formControlName="platform" [data]="isPlatform" [placeholder]="'-- Select --'"></ng-select2>
                </div>
              </div>
            </div>
            <div class="col-lg-12">
              <div class="mb-3 row">
                <label for="version_code" class="col-sm-2 form-label align-self-center mb-lg-0 text-end">Version Code *</label>
                <div class="col-sm-5">
                    <input class="form-control" type="text" id="version_code" placeholder="Version Code" formControlName="version_code">
                </div>
              </div>
            </div>
            <div class="col-lg-12">
              <div class="mb-3 row">
                <label for="version_name" class="col-sm-2 form-label align-self-center mb-lg-0 text-end">Version Name *</label>
                <div class="col-sm-5">
                    <input class="form-control" type="text" id="version_name" placeholder="Version Name" formControlName="version_name">
                </div>
              </div>
            </div>
            <div class="col-lg-12">
              <div class="mb-3 row">
                <label for="version_name" class="col-sm-2 form-label align-self-center mb-lg-0 text-end">Is Force Update *</label>
                <div class="col-sm-5">
                  <ng-select2 formControlName="is_force_update" [data]="isForce" [placeholder]="'-- Select --'"></ng-select2>
                </div>
              </div>
            </div>
            <div class="col-lg-12">
              <div class="mb-3 row">
                <label for="version_name" class="col-sm-2 form-label align-self-center mb-lg-0 text-end">Is Maintenance *</label>
                <div class="col-sm-5">
                  <ng-select2 formControlName="is_maintenance" [data]="isMaintenance" [placeholder]="'-- Select --'"></ng-select2>
                </div>
              </div>
            </div>
            <div class="col-lg-12">
              <div class="col-sm-7">
                <button type="submit" class="btn btn-soft-primary btn-md float-end mb-3">Simpan</button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>