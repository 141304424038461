<form *ngIf="isForm == 0" [formGroup]="searchForm">
  <div class="row mb-3">
    <div class="col-lg-9 mb-1">
      <input type="text" formControlName="keyword" class="form-control" placeholder="Keyword">
    </div>
    <div class="col-lg-3">
      <div class="float-end">
        <button class="btn btn-md btn-primary mr-5" (click)="onSearch()"><i class="fa fa-search"></i> Cari</button>
        <button class="btn btn-md btn-danger mr-5" (click)="onReset()"><i class="fa fa-redo"></i> Reset</button>
        <button class="btn btn-md btn-dark" data-bs-toggle="modal" data-bs-target="#formData"  (click)="onShowAdd()"><i class="fa fa-plus"></i> Tambah</button>
      </div>
    </div>
  </div>
</form>
<form *ngIf="isForm == 1" [formGroup]="searchForm">
  <div class="row mb-3">
    <div class="col-lg-10 mb-1">
      <input type="text" formControlName="keyword" class="form-control" placeholder="Keyword">
    </div>
    <div class="col-lg-2">
      <div class="float-end">
        <button class="btn btn-md btn-primary mr-5" (click)="onSearch()"><i class="fa fa-search"></i> Cari</button>
        <button class="btn btn-md btn-danger mr-5" (click)="onReset()"><i class="fa fa-redo"></i> Reset</button>
      </div>
    </div>
  </div>
</form>
<form *ngIf="isForm == 2" [formGroup]="searchForm">
  <div class="row mb-3">
    <div class="col-lg-5 mb-1">
      <input type="text" formControlName="keyword" class="form-control" placeholder="Keyword">
    </div>
    <div class="col-lg-2 mb-1">
      <ng-select2 formControlName="type" [data]="dataType" [placeholder]="'-- Pilih Tipe --'"></ng-select2>
    </div>
    <div class="col-lg-2 mb-1">
      <ng-select2 formControlName="status" [data]="dataStatus" [placeholder]="'-- Pilih Status --'"></ng-select2>
    </div>
    <div class="col-lg-3">
      <div class="float-end">
        <button class="btn btn-md btn-primary mr-5" (click)="onSearch()"><i class="fa fa-search"></i> Cari</button>
        <button class="btn btn-md btn-danger mr-5" (click)="onReset()"><i class="fa fa-redo"></i> Reset</button>
        <button class="btn btn-md btn-dark" data-bs-toggle="modal" data-bs-target="#formData"  (click)="onShowAdd()"><i class="fa fa-plus"></i> Tambah</button>
      </div>
    </div>
  </div>
</form>
<form *ngIf="isForm == 3" [formGroup]="searchForm">
  <div class="row mb-3">
    <div class="col-lg-7 mb-1">
      <input type="text" formControlName="keyword" class="form-control" placeholder="Keyword">
    </div>
    <div class="col-lg-2 mb-1">
      <ng-select2 formControlName="surah" [data]="dataSurah" [placeholder]="'-- Pilih Surat --'"></ng-select2>
    </div>
    <div class="col-lg-3">
      <div class="float-end">
        <button class="btn btn-md btn-primary mr-5" (click)="onSearch()"><i class="fa fa-search"></i> Cari</button>
        <button class="btn btn-md btn-danger mr-5" (click)="onReset()"><i class="fa fa-redo"></i> Reset</button>
        <button class="btn btn-md btn-dark" data-bs-toggle="modal" data-bs-target="#formData"  (click)="onShowAdd()"><i class="fa fa-plus"></i> Tambah</button>
      </div>
    </div>
  </div>
</form>
<form *ngIf="isForm == 4" [formGroup]="searchForm">
  <div class="row mb-3">
    <div class="col-lg-8 mb-1">
      <input type="text" formControlName="keyword" class="form-control" placeholder="Keyword">
    </div>
    <div class="col-lg-2 mb-1">
      <ng-select2 formControlName="status" [data]="dataStatusApproval" [placeholder]="'-- Pilih Status --'"></ng-select2>
    </div>
    <div class="col-lg-2">
      <div class="float-end">
        <button class="btn btn-md btn-primary mr-5" (click)="onSearch()"><i class="fa fa-search"></i> Cari</button>
        <button class="btn btn-md btn-danger mr-5" (click)="onReset()"><i class="fa fa-redo"></i> Reset</button>
<!--        <button class="btn btn-md btn-dark" data-bs-toggle="modal" data-bs-target="#formData"  (click)="onShowAdd()"><i class="fa fa-plus"></i> Tambah</button>-->
      </div>
    </div>
  </div>
</form>

