import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, FormArray, FormControl, Validators} from '@angular/forms';
import {ToastrService} from 'ngx-toastr';
import {AuthenticationService} from '../../services/auth/authentication.service';
import {ApiService} from '../../services/api/api.service';
import * as moment from 'moment';
import {Router} from "@angular/router";

declare const $: any;

@Component({
  selector: 'app-audio',
  templateUrl: './audio-surat.component.html',
  styleUrls: ['./audio-surat.component.css']
})
export class AudioSuratComponent implements OnInit {

  showFormAdd: any = { 'isFormSearch': 1, 'isType': '',};
  isForm: any;
  isData: any = [];
  isOffset: number = 0;
  isTotalVw: number = 0;
  isTotalAll: number = 0;

  isKeyword: string = '';

  attachment: any = [];
  dataSurah: any = [];

  constructor(
    private fb: FormBuilder,
    private toastr: ToastrService,
    private authService: AuthenticationService,
    private apiService: ApiService,
    private router: Router,
  ) {
  }

  ngOnInit(): void {
    this.getData();
    this.resetForm();
    this.getSurah();
  }

  async resetForm() {
    this.isForm = this.fb.group({
      id: new FormControl(''),
      surah: new FormControl('', [Validators.required]),
      ayah: new FormControl('', [Validators.required]),
      file: new FormControl('', [Validators.required]),
    })
  }

  async getSurah() {
    try {
      this.apiService.methodGet(`surah`, this.authService.getToken()).subscribe((response) => {
        if (response.status == 200) {
          this.dataSurah = response.data;
        }
      })
    } catch (error) {
      console.log(error);
    }
  }

  async onReset() {
    this.isData = await [];
    this.isOffset = await 0;
    this.isTotalVw = await 0;
    this.isTotalAll = await 0;
    this.isKeyword = await '';
    await this.resetForm();
    await this.getData();
  }

  async onSearch($event: any) {
    this.isData = await [];
    this.isOffset = await 0;
    this.isTotalVw = await 0;
    this.isTotalAll = await 0;
    this.isKeyword = await ($event['keyword'] ? $event['keyword'] : '');
    this.getData();
  }

  async getData() {
    let searchData = {"keyword": this.isKeyword};
    try {
      this.apiService.methodGet(`audio/surah/${this.isOffset}/${encodeURIComponent(JSON.stringify(searchData))}`, this.authService.getToken()).subscribe((response) => {
        if (response.status == 200) {
          (response.total ? response.data.forEach((element: any) => {
            element.updatedAt = moment(element.updatedAt).locale('id').format("D MMMM YYYY HH:mm");
            this.isData.push(element);
          }) : false);
          this.isTotalVw = this.isData.length;
          this.isTotalAll = response.total;
        }
      })
    } catch (error) {
      console.log(error);
    }
  }

  async loadMore() {
    this.isOffset = await this.isTotalVw;
    await this.getData();
  }

  async onManage(i: number) {
    try {
      await this.router.navigateByUrl(`/audio-file?id=${this.isData[i].id}`, { replaceUrl: true }).then(() => {
        window.location.reload();
      });
    } catch (error) {
      console.log(error);
    }
  }


}
