<div class="row">
  <div class="col-lg-12">
    <div class="card">
      <div class="card-body">
        <app-search (isFormAdd)="onShowAdd()" (isReset)="onReset()" (isSearch)="onSearch($event)"
                    [isFormSrc]="showFormAdd"></app-search>
        <div class="row">
          <div class="col-lg-12">
            <div class="table-responsive">
              <table class="table table-hover mb-0">
                <thead class="thead-light">
                <tr>
                  <th class="text-center" [width]="50">No</th>
                  <th class="">Nama</th>
                  <th class="">Background</th>
                  <th class="">Kode</th>
                  <th class="">Tipe</th>
                  <th class="">Jumlah</th>
                  <th class="">Masa Aktif</th>
                  <th class="">Harga</th>
                  <th class="">Status</th>
                  <th class="text-center" [width]="150">Aksi</th>
                </tr>
                </thead>
                <tbody>
                <ng-container *ngFor="let v of isData; let i = index">
                  <tr>
                    <th class="text-center">{{i + 1}}</th>
                    <td class="">{{v.name}}</td>
                    <td class="">{{v.voucher_background.name}}</td>
                    <td class="">{{v.code}}</td>
                    <td class="text-right">
                      <span *ngIf="v.type == 0" class="badge bg-primary-gradient">UNIK</span>
                      <span *ngIf="v.type == 1" class="badge bg-danger-gradient">MASAL</span>
                    </td>
                    <td class="text-center">{{v.amount}}</td>
                    <td class="text-center">{{v.expired_day}} hari</td>
                    <td class="text-right">{{v.price}}</td>
                    <td class="">
                      <span *ngIf="v.status == 1" class="badge bg-success-gradient">ACTIVE</span>
                      <span *ngIf="v.status == 0" class="badge bg-light-gradient">NOT ACTIVE</span>
                    </td>
                    <td class="text-center">
                      <a class="btn btn-xs btn-warning text-light mr-5" (click)="onEdit(i)" data-bs-toggle="modal"
                         data-bs-target="#formData"> <i class="fa fa-edit"></i> Edit</a>
                      <a class="btn btn-xs btn-danger mr-5" (click)="onDelete(v.id)"> <i class="fa fa-trash"></i> Hapus</a>
                      <a class="btn btn-xs btn-dark text-light mr-5" (click)="viewVoucher(i)"> <i
                        class="fa fa-print"></i> Cetak</a>
                    </td>
                  </tr>
                </ng-container>
                </tbody>
              </table><!--end /table-->
            </div>
          </div>
        </div>
        <div class="row mt-3">
          <div class="col-sm-3">
            <div class="pull-left text-uppercase">
              <strong><i class="fe fe-list"></i> TOTAL DATA : {{ isTotalAll }}</strong>
            </div>
          </div>
          <div class="col-sm-6 text-center" *ngIf="isTotalAll > isTotalVw">
            <div class="text-center" (click)="loadMore()">
              <a href="javascript:;" class="btn btn-xs btn-outline-primary">Load more..</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- FORM -->
<form [formGroup]="isForm" (ngSubmit)="onSubmit()">
  <div class="modal fade" id="formData" aria-labelledby="formDataLabel" aria-hidden="true" data-keyboard="false"
       data-backdrop="static">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h6 class="modal-title" id="formDataLabel">FORM DATA</h6>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-lg-12">
              <div class="mb-3 row">
                <label class="col-sm-2 form-label align-self-center mb-lg-0 text-end">Nama *</label>
                <div class="col-sm-8">
                  <input type="text" class="form-control" placeholder="Nama Voucher" formControlName="name">
                </div>
              </div>
            </div>
            <div class="col-lg-12">
              <div class="mb-3 row">
                <label class="col-sm-2 form-label align-self-center mb-lg-0 text-end">Background *</label>
                <div class="col-sm-8">
                  <ng-select2 formControlName="voucher_background_id" [data]="dataBackground"
                              [placeholder]="'-- Pilih Background --'"></ng-select2>
                </div>
              </div>
            </div>
            <div class="col-lg-12">
              <div class="mb-1 row">
                <label class="col-sm-2 form-label align-self-center mb-lg-0 text-end">Kode *</label>
                <div class="col-sm-8">
                  <input type="text" (keyup)="validationCode($event)" [attr.disabled]="isForm.value.id?true:null"
                         [maxlength]="isForm.value.id?15:10" class="form-control" placeholder="Kode Voucher"
                         formControlName="code">

                </div>
              </div>
            </div>
            <div class="col-lg-12">
              <div class="mb-2 row">
                <div class="col-sm-2"></div>
                <div class="col-sm-8">
                  <div class="alert alert-danger">
                    <small>
                      <b>Note :</b><br>
                      <ul>
                        <li>
                          Kode Awal Voucher (Misal: <span class="text-dark"><b>HATAM</b></span> akan menjadi: <span
                          class="text-dark"><b>HATAM</b></span><span class="text-primary"><b>KODEUNIK</b></span> )
                        </li>
                        <li>
                          Kode Awal Voucher maksimal 10 karakter
                        </li>
                        <li>
                          KODE UNIK akan digenerate oleh system secara otomatis
                        </li>
                      </ul>
                    </small>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-12">
              <div class="mb-3 row">
                <label class="col-sm-2 form-label align-self-center mb-lg-0 text-end">Tipe *</label>
                <div class="col-sm-8">
                  <ng-select2 formControlName="type" [data]="dataType" [placeholder]="'-- Pilih Tipe --'"></ng-select2>
                </div>
              </div>
            </div>
            <div class="col-lg-12">
              <div class="mb-3 row">
                <label class="col-sm-2 form-label align-self-center mb-lg-0 text-end">Jumlah *</label>
                <div class="col-sm-8">
                  <input type="number" [min]="0" max="1000" class="form-control" placeholder="Jumlah Voucher"
                         formControlName="amount">
                </div>
              </div>
            </div>
            <div class="col-lg-12">
              <div class="mb-3 row">
                <label class="col-sm-2 form-label align-self-center mb-lg-0 text-end">Masa Aktif *</label>
                <div class="col-sm-8">
                  <input type="text" class="form-control" placeholder="Masa Aktif" formControlName="expired_day">
                  <small class="text-danger">Satuan hari, misal : 60 hari</small>
                </div>
              </div>
            </div>
            <div class="col-lg-12">
              <div class="mb-3 row">
                <label class="col-sm-2 form-label align-self-center mb-lg-0 text-end">Harga *</label>
                <div class="col-sm-8">
                  <input type="text" class="form-control" placeholder="Harga Voucher" formControlName="price">
                </div>
              </div>
            </div>
            <div class="col-lg-12">
              <div class="mb-3 row">
                <label class="col-sm-2 form-label align-self-center mb-lg-0 text-end">Status *</label>
                <div class="col-sm-8">
                  <ng-select2 formControlName="status" [data]="dataStatus"
                              [placeholder]="'-- Pilih Status --'"></ng-select2>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="submit" class="btn btn-soft-primary btn-md" [disabled]="!isForm.valid"><i class="fa fa-spinner fa-spin" *ngIf="isLoading"></i> Simpan</button>
          <button type="button" class="btn btn-soft-danger btn-md" data-bs-dismiss="modal">Batal</button>
        </div>
      </div>
    </div>
  </div>
</form>

<div class="modal fade" id="modalVoucher" aria-labelledby="modalVoucherLabel" aria-hidden="true" data-keyboard="false"
     data-backdrop="static">
  <div class="modal-dialog modal-lg modal-dialog-scrollable">
    <div class="modal-content">
      <div class="modal-header">
        <h6 class="modal-title" id="modalVoucherLabel">FORM DATA</h6>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <div class="row" id="pdfVoucher">
          <table>
            <ng-container *ngFor="let data of isAmountVoucher; let i = index">
              <tr>
                <td>
                  <div class="card-voucher" style="background-image: url('{{isDetail.voucher_background.image}}');">
                    <p style="margin-top: 9.5rem !important; margin-left: 10px;" class="text-dark">
                      <b>{{isDetail.code}}</b>
                    </p>
                  </div>
                </td>
                <td>
                  <div class="card-voucher" style="background-image: url('{{isDetail.voucher_background.image}}');">
                    <p style="margin-top: 9.5rem !important; margin-left: 10px;" class="text-dark">
                      <b>{{isDetail.code}}</b>
                    </p>
                  </div>
                </td>
              </tr>
              <tr *ngIf="(i+1) >= 4 && (i+1)%4 == 0">
                <td colspan="2">
                  <div style="page-break-before:always">&nbsp;</div>
                </td>
              </tr>
            </ng-container>
            <tr *ngIf="isDetail.mod">
              <td>
                <div class="card-voucher" style="background-image: url('{{isDetail.voucher_background.image}}');">
                  <p style="margin-top: 9.5rem !important; margin-left: 10px;" class="text-dark">
                    <b>{{isDetail.code}}</b>
                  </p>
                </div>
              </td>
            </tr>
          </table>
          <!-- <ng-container *ngFor="let data of isAmountVoucher; let i = index">
              <div class="col-md-6 float">
                <div style="flex: 0 0 auto;width: 50%;">
                  <div class="card-voucher" style="background-image: url('{{directoryFile}}{{isDetail.voucher_background.image}}');">
                    <p style="margin-top: 9.5rem !important; margin-left: 10px;" class="text-dark">
                      <b>{{isDetail.code}}</b>
                    </p>
                  </div>
              </div>
              <div *ngIf="(i+1)%4 == 0" style="page-break-before:always">&nbsp;</div>
            </div>
            </ng-container> -->
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-soft-primary btn-md" (click)="downloadAsPDF()">Cetak</button>
        <button type="button" class="btn btn-soft-danger btn-md" data-bs-dismiss="modal">Batal</button>
      </div>
    </div>
  </div>
</div>
