import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormArray, FormControl, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { AuthenticationService } from '../../services/auth/authentication.service';
import { ApiService } from '../../services/api/api.service';
import { FormatService } from '../../services/format/format.service';
import * as moment from 'moment';

declare const printDiv: any;
declare const $:any;

@Component({
  selector: 'app-voucher-list',
  templateUrl: './voucher-list.component.html',
  styleUrls: ['./voucher-list.component.css']
})
export class VoucherListComponent implements OnInit {

  showFormAdd: any = { 'isFormSearch': 2, 'isType': 'add',};
  isForm : any;
  isDetailData : any ;
  isData : any = [];
  isDetail : any = [];
  isAmountVoucher : any = [];
  isOffset: number = 0;
  isTotalVw : number = 0;
  isTotalAll : number = 0;

  isKeyword : string = '';
  isType : string = '';
  isStatus : string = '';

  dataBackground : any = [];
  dataType : any = [{'id':1,'text':'MASAL'},{'id':0,'text':'UNIK'}];
  dataStatus : any = [{'id':1,'text':'ACTIVE'},{'id':0,'text':'NOT ACTIVE'}];

  isLoading = false;

  constructor(
    private fb: FormBuilder,
    private toastr: ToastrService,
    private authService: AuthenticationService,
    private apiService: ApiService,
    private formatService: FormatService,
  ) { }

  ngOnInit(): void {
    this.getData();
    this.resetForm();
    this.getBackground();
  }
  async resetForm(){
    this.isForm = this.fb.group({
      id : new FormControl(''),
      admin_id : new FormControl(this.authService.getSession().id),
      name : new FormControl('', [Validators.required]),
      code : new FormControl('', [Validators.required]),
      amount : new FormControl('', [Validators.required]),
      price : new FormControl('', [Validators.required]),
      expired_day : new FormControl('', [Validators.required]),
      type : new FormControl('', [Validators.required]),
      status : new FormControl('', [Validators.required]),
      voucher_background_id : new FormControl('', [Validators.required]),
    })
  }
  async getBackground(){
    try{
      this.apiService.methodGet(`voucher-background/list`,this.authService.getToken()).subscribe((response)=>{
        if(response.status == 200) {
          this.dataBackground = response.data;
        }
      })
    }catch(error){
      console.log(error);
    }
  }
  async onReset() {
    this.isData    = await [];
    this.isDetail    = await [];
    this.isOffset = await 0;
    this.isTotalVw = await 0;
    this.isTotalAll = await 0;
    this.isKeyword = await '';
    this.isType = await '';
    this.isStatus = await '';
    await this.resetForm();
    await this.getData();
  }
  async onSearch($event: any){
    this.isData    = await [];
    this.isDetail    = await [];
    this.isOffset = await 0;
    this.isTotalVw = await 0;
    this.isTotalAll = await 0;
    this.isKeyword = await ($event['keyword']?$event['keyword']:'');
    this.isType = await ($event['type']?$event['type']:'');
    this.isStatus = await ($event['status']?$event['status']:'');
    this.getData();
  }
  async onDelete(id:number){
    try{
      await this.formatService.alertBeforeDelete().then(async (result) => {
        if (result.value) {
          this.apiService.methodDelete(`voucher/${id}`, this.authService.getToken()).subscribe(async (response)=>{
            if(response.status == 200) {
              await this.toastr.success(`Berhasil dihapus`);
              this.onReset();
            }
          });
        }
      });
    }catch(error){
      console.log(error);
    }
  }
  async getData(){
    let searchData = {"keyword":this.isKeyword,"type":this.isType,"status":this.isStatus};
    try{
      this.apiService.methodGet(`voucher/${this.isOffset}/${encodeURIComponent(JSON.stringify(searchData))}`,this.authService.getToken()).subscribe((response)=>{
        if(response.status == 200) {
          (response.total ? response.data.forEach((element: any) => {
            element.voucher_background.image = `${response.directoryFile}voucher/${element.voucher_background.image}`;
            this.isData.push(element);
          }) : false);
          this.isTotalVw = this.isData.length;
          this.isTotalAll = response.total;
        }
      })
    }catch(error){
      console.log(error);
    }
  }
  async loadMore() {
    this.isOffset = await this.isTotalVw;
    await this.getData();
  }
  async onShowAdd(){
    this.resetForm();
    $('#formData').modal({backdrop: 'static', keyboard: false})
  }
  async onSubmit(){
    try{
      if(this.isForm.valid || this.isForm.pristine){
        this.isLoading = true;
        this.isForm.value.price = parseInt(this.isForm.value.price);
        if(this.isForm.value.id){
          this.apiService.methodPut(`voucher/${this.isForm.value.id}`, this.isForm.value, this.authService.getToken()).subscribe(async(response)=>{
            $('#formData').modal('hide');
            this.isLoading = false;
            if (response.status == 200) {
              await this.toastr.success(`Berhasil diupdate`);
              await this.onReset();
            } else {
              await this.toastr.error(response.message || "Gagal memuat data");
            }
          });
        }else{
          this.apiService.methodPost(`voucher`, this.isForm.value, this.authService.getToken()).subscribe(async(response)=>{
            $('#formData').modal('hide');
            this.isLoading = false;
            if (response.status == 200) {
              await this.toastr.success(`Berhasil disimpan`);
              await this.onReset();
            } else {
              await this.toastr.error(response.message || "Gagal memuat data");
            }
          });
        }
      }
    }catch(error){
      await this.toastr.error(error.message || `Gagal menyimpan data`);
    }
  }
  async onEdit(i:number){
    try{
      this.resetForm();
      const dataEdit: any = Object.assign({}, this.isData[i]);
      await this.isForm.patchValue(dataEdit);
      this.isForm.controls['code'].updateValueAndValidity();
      await $('#formData').modal({ backdrop: 'static', keyboard: true, show: true });
    }catch(error){
      console.log(error);
    }
  }

  async validationCode(code : any){
    if(code.target.value){
      if(code.target.value.length > 10){
        this.toastr.error(`Maksimal 10 karakter !!`);
      }
    }
  }

  async viewVoucher(index : any){
    this.isAmountVoucher = [];
    this.isDetail = this.isData[index];
    let idxMod =  this.isDetail.amount % 2;
    if(idxMod){
      this.isAmountVoucher = new Array(((this.isDetail.amount-idxMod)/2));
      this.isDetail['mod'] = idxMod;
    }else{
      this.isAmountVoucher = new Array(((this.isDetail.amount)/2));
    }

    console.log(this.isDetail);


    $("#modalVoucher").modal("show");
  }

  downloadAsPDF() : void{
    printDiv();
    // this.getBase64ImageFromUrl('https://admin.hatam.org/assets/upload/image/Voucher_HATAM_KOSONG1.jpg')
    // .then(result =>{
    //   console.log(result);
    // })
    // .catch(err => console.error(err));
  }

  async getBase64ImageFromUrl(imageUrl) {
    var res = await fetch(imageUrl);
    var blob = await res.blob();

    return new Promise((resolve, reject) => {
      var reader  = new FileReader();
      reader.addEventListener("load", function () {
          resolve(reader.result);
      }, false);

      reader.onerror = () => {
        return reject(this);
      };
      reader.readAsDataURL(blob);
    })
  }
}
