import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormControl, Validators} from '@angular/forms';
import {ToastrService} from 'ngx-toastr';
import {AuthenticationService} from '../../services/auth/authentication.service';
import {ApiService} from '../../services/api/api.service';
import {FormatService} from '../../services/format/format.service';
import * as moment from "moment/moment";

declare const $: any;

@Component({
  selector: 'app-content-feed',
  templateUrl: './content-feed.component.html',
  styleUrls: ['./content-feed.component.css']
})
export class ContentFeedComponent implements OnInit {
  showFormAdd: any = {'isFormSearch': 0, 'isType': 'add',};
  isForm: any;
  isData: any = [];
  isOffset: number = 0;
  isTotalVw: number = 0;
  isTotalAll: number = 0;

  isKeyword: string = '';

  attachment: any = [];

  dataTeacher: any = [{'id': 1, 'text': 'Hatam Official'}, {'id': 2, 'text': 'Ustadz Abdul Latif'}, {
    'id': 3,
    'text': 'Rohdian Al-Ahad'
  }];

  isLoading = false;

  constructor(
    private fb: FormBuilder,
    private toastr: ToastrService,
    private authService: AuthenticationService,
    private apiService: ApiService,
    private formatService: FormatService,
  ) {
  }

  async ngOnInit(): Promise<void> {
    await this.resetForm();
    await this.getData();
  }

  async resetForm() {
    this.isForm = this.fb.group({
      id: new FormControl(''),
      teacher_id: new FormControl('', [Validators.required]),
      caption: new FormControl('', [Validators.required]),
      photos: new FormControl('', [Validators.required]),
    })
  }

  async onReset() {
    this.isData = [];
    this.isOffset = 0;
    this.isTotalVw = 0;
    this.isTotalAll = 0;
    this.isKeyword = '';
    await this.resetForm();
    await this.getData();
  }

  async onSearch($event: any) {
    this.isData = [];
    this.isOffset = 0;
    this.isTotalVw = 0;
    this.isTotalAll = 0;
    this.isKeyword = await ($event['keyword'] ? $event['keyword'] : '');
    await this.getData();
  }

  async onDelete(id: number) {
    try {
      await this.formatService.alertBeforeDelete().then(async (result) => {
        if (result.value) {
          this.apiService.methodDelete(`teacher-feed/${id}`, this.authService.getToken()).subscribe(async (response) => {
            if (response.status == 200) {
              await this.toastr.success(`Berhasil dihapus`);
              await this.onReset();
            }
          });
        }
      });
    } catch (error) {
      console.log(error);
    }
  }

  async getData() {
    let searchData = {"keyword": this.isKeyword};
    try {
      this.apiService.methodGet(`teacher-feed/${this.isOffset}/${encodeURIComponent(JSON.stringify(searchData))}`, this.authService.getToken()).subscribe((response) => {
        if (response.status == 200) {
          (response.total ? response.data.forEach((element: any) => {
            element.photo = `${response.directoryFile}user/feed/${element.photo}`
            element.createdAt = moment(element.createdAt).locale('id').format("D MMMM YYYY HH:mm");
            this.isData.push(element);
          }) : false);
          this.isTotalVw = this.isData.length;
          this.isTotalAll = response.total;
        }
      })
    } catch (error) {
      console.log(error);
    }
  }

  async loadMore() {
    this.isOffset = this.isTotalVw;
    await this.getData();
  }

  async onShowAdd() {
    await this.resetForm();
    $('#formData').modal({backdrop: 'static', keyboard: false})
  }

  async onSubmit() {
    try {
      if (this.isForm.valid) {
        this.isLoading = true;
        let formValue = await new FormData();
        formValue.append('id', this.isForm.value['id']);
        formValue.append('teacher_id', this.isForm.value['teacher_id']);
        formValue.append('caption', this.isForm.value['caption']);
        formValue.append('photo', this.isForm.value['photos']);
        if (this.isForm.value.id) {
          this.apiService.methodPutWithFormdata(`teacher-feed/${this.isForm.value.id}`, formValue, this.authService.getToken()).subscribe(async (response) => {
            $('#formData').modal('hide');
            this.isLoading = false;
            if (response.status == 200) {
              await this.toastr.success(`Berhasil diupdate`);
              await this.onReset();
            } else {
              await this.toastr.error(response.message || "Gagal memuat data");
            }
          });
        } else {
          this.apiService.methodPostWithFormdata(`teacher-feed`, formValue, this.authService.getToken()).subscribe(async (response) => {
            $('#formData').modal('hide');
            this.isLoading = false;
            if (response.status == 200) {
              await this.toastr.success(`Berhasil disimpan`);
              await this.onReset();
            } else {
              await this.toastr.error(response.message || "Gagal memuat data");
            }
          });
        }
      }
    } catch (error) {

    }
  }

  async onEdit(i: number) {
    try {
      await this.resetForm();
      const dataEdit: any = Object.assign({}, this.isData[i]);
      await this.isForm.patchValue(dataEdit);
      this.isForm.controls['photos'].clearValidators();
      this.isForm.controls['photos'].updateValueAndValidity();
      await $('#formData').modal({backdrop: 'static', keyboard: true, show: true});
    } catch (error) {
      console.log(error);
    }
  }

  async onFileSelect(e, n) {
    if (e.target.files.length > 0) {
      this.attachment = e.target.files[0];
      this.isForm.value[n] = this.attachment;
    }
  }
}
