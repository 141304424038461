
<div class="row">
  <div class="col-lg-12">
    <div class="card">
      <div class="card-body">
        <app-search (isReset)="onReset()" (isSearch)="onSearch($event)" [isFormSrc]="showFormAdd"></app-search>
        <div class="row">
          <div class="col-lg-12">
            <div class="table-responsive">
              <table class="table table-hover mb-0">
                <thead class="thead-light">
                <tr>
                  <th class="text-center" [width]="50">No</th>
                  <th class="" >User</th>
                  <th class="" >Voucher</th>
                  <th class="" >Kode</th>
                  <th class="text-center" >Masa Aktif</th>
                  <th class="text-center" >Tipe</th>
                  <th class="" >Tanggal Mulai</th>
                  <th class="" >Tanggal Akhir</th>
                </tr>
                </thead>
                <tbody>
                <ng-container *ngFor="let v of isData; let i = index">
                  <tr>
                    <th class="text-center" >{{i + 1}}</th>
                    <td class="" >{{v.user.name}}</td>
                    <td class="" >{{v.voucher.name}}</td>
                    <td class="" >{{v.voucher.code}}</td>
                    <td class="text-center" >{{v.voucher.expired_day}} hari</td>
                    <td class="text-center" >
                    <span *ngIf="v.voucher.type == 0" class="badge bg-primary-gradient">UNIK</span>
                    <span *ngIf="v.voucher.type == 1" class="badge bg-danger-gradient">MASAL</span>
                    </td>
                    <td class="" >{{v.createdAt}}</td>
                    <td class="" >{{v.expired_date}}</td>
                  </tr>
                </ng-container>
                </tbody>
              </table><!--end /table-->
            </div>
          </div>
        </div>
        <div class="row mt-3">
          <div class="col-sm-3">
            <div class="pull-left text-uppercase">
              <strong><i class="fe fe-list"></i> TOTAL DATA : {{ isTotalAll }}</strong>
            </div>
          </div>
          <div class="col-sm-6 text-center" *ngIf="isTotalAll > isTotalVw">
            <div class="text-center" (click)="loadMore()">
              <a href="javascript:;" class="btn btn-xs btn-outline-primary" >Load more..</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
