<div class="row justify-content-center">
  <div class="col-md-6 col-lg-3">
    <div class="card report-card card-dashboard">
      <div class="card-body">
        <div class="row d-flex justify-content-center">
          <div class="col">
            <p class="text-dark mb-1 font-weight-semibold">Audio</p>
            <h3 class="m-0">{{dataSummary.audio}}</h3>
          </div>
          <div class="col-auto align-self-center">
            <div class="report-main-icon bg-soft-info">
              <i class="align-self-center icon-md fa fa-file-audio"></i>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-md-6 col-lg-3">
    <div class="card report-card card-dashboard">
      <div class="card-body">
        <div class="row d-flex justify-content-center">
          <div class="col">
            <p class="text-dark mb-1 font-weight-semibold">User</p>
            <h3 class="m-0">{{dataSummary.user}}</h3>
          </div>
          <div class="col-auto align-self-center">
            <div class="report-main-icon bg-soft-pink">
              <i class="align-self-center icon-md fa fa-users"></i>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-md-6 col-lg-3">
    <div class="card report-card card-dashboard">
      <div class="card-body">
        <div class="row d-flex justify-content-center">
          <div class="col">
            <p class="text-dark mb-1 font-weight-semibold">Konten Feed</p>
            <h3 class="m-0">{{dataSummary.teacher_feed}}</h3>
          </div>
          <div class="col-auto align-self-center">
            <div class="report-main-icon bg-soft-warning">
              <i class="align-self-center icon-md fa fa-clone"></i>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-md-6 col-lg-3">
    <div class="card report-card card-dashboard">
      <div class="card-body">
        <div class="row d-flex justify-content-center">
          <div class="col">
            <p class="text-dark mb-1 font-weight-semibold">Konten Video</p>
            <h3 class="m-0">{{dataSummary.video}}</h3>
          </div>
          <div class="col-auto align-self-center">
            <div class="report-main-icon bg-soft-danger">
              <i class="align-self-center icon-md fa fa-desktop"></i>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="row justify-content-center">
  <div class="col-md-6 col-lg-3">
    <div class="card report-card card-dashboard">
      <div class="card-body">
        <div class="row d-flex justify-content-center">
          <div class="col">
            <p class="text-dark mb-1 font-weight-semibold">Playlist</p>
            <h3 class="m-0">{{dataSummary.playlist}}</h3>
          </div>
          <div class="col-auto align-self-center">
            <div class="report-main-icon bg-soft-info">
              <i class="align-self-center icon-md fa fa-music"></i>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-md-6 col-lg-3">
    <div class="card report-card card-dashboard">
      <div class="card-body">
        <div class="row d-flex justify-content-center">
          <div class="col">
            <p class="text-dark mb-1 font-weight-semibold">SahamQu</p>
            <h3 class="m-0">{{dataSummary.sahamqu}}</h3>
          </div>
          <div class="col-auto align-self-center">
            <div class="report-main-icon bg-soft-pink">
              <i class="align-self-center icon-md fa fa-heart"></i>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-md-6 col-lg-3">
    <div class="card report-card card-dashboard">
      <div class="card-body">
        <div class="row d-flex justify-content-center">
          <div class="col">
            <p class="text-dark mb-1 font-weight-semibold">Berlangganan Voucher</p>
            <h3 class="m-0">{{dataSummary.user_voucher}}</h3>
          </div>
          <div class="col-auto align-self-center">
            <div class="report-main-icon bg-soft-warning">
              <i class="align-self-center icon-md fa fa-credit-card"></i>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-md-6 col-lg-3">
    <div class="card report-card card-dashboard">
      <div class="card-body">
        <div class="row d-flex justify-content-center">
          <div class="col">
            <p class="text-dark mb-1 font-weight-semibold">Berlangganan Store</p>
            <h3 class="m-0">{{dataSummary.user_store}}</h3>
          </div>
          <div class="col-auto align-self-center">
            <div class="report-main-icon bg-soft-danger">
              <i class="align-self-center icon-md fab fa-google-play"></i>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="row justify-content-center">
  <div class="col-md-6 col-lg-0" id="daily-visit">
    <div class="card report-card card-dashboard">
      <div class="card-body">
        <div class="row d-flex justify-content-center">
          <div class="col">
            <div class="box">
              <div id="area"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-md-6 col-lg-0">
    <div class="card report-card card-dashboard" id="gender">
      <div class="card-body">
        <div class="row d-flex justify-content-center">
          <div class="col">
            <div class="box">
              <div id="donut"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
