<div class="row">
  <div class="col-lg-12">
    <div class="card">
      <div class="card-body">
        <app-search (isReset)="onReset()" (isSearch)="onSearch($event)" [isFormSrc]="showFormAdd"></app-search>
        <div class="row">
          <div class="col-lg-12">
            <div class="table-responsive">
              <table class="table table-hover mb-0">
                <thead class="thead-light">
                  <tr>
                      <th class="text-center" [width]="50">No</th>
                      <th class="text-center" >Aksi</th>
                      <th class="" >Nama Guru</th>
                      <th class="" >Nama Grup</th>
                      <th class="" >Photo</th>
                      <th class="" >Deskripsi</th>
                      <th class="" >Max Member</th>
                      <th class="" >Biaya</th>
                      <th class="" >Jumlah Target Hafalan</th>
                      <th class="" >Jumlah Sistem Setoran</th>
                      <th class="" >Siklus Setoran</th>
                      <th class="" >Periode</th>
                  </tr>
                </thead>
                <tbody>
                  <ng-container *ngFor="let v of isData; let i = index">
                    <tr>
                      <th class="text-center" >{{i + 1}}</th>
                      <td class="text-center">
                        <a class="btn btn-xs btn-success text-light mr-5" (click)="onMember(v.id)"> <i class="fa fa-users"></i> Member</a>
                      </td>
                      <td class="" >{{v.teacher.user.name}}</td>
                      <td class="">{{v.name}}</td>
                      <td class="">-</td>
                      <td class="">{{v.description}}</td>
                      <td class="">{{v.max_member}}</td>
                      <td class="">{{v.fee}}</td>
                      <td class="">{{v.target_amount}} {{v.target_category}}</td>
                      <td class="">{{v.system_amount}} {{v.system_category}}</td>
                      <td class="">{{v.system_cycle}}</td>
                      <td class="">{{v.period_start}} <b>s/d</b> {{v.period_finish}}</td>
                    </tr>
                  </ng-container>
                </tbody>
              </table><!--end /table-->
            </div>
          </div>
        </div>
        <div class="row mt-3">
          <div class="col-sm-3">
            <div class="pull-left text-uppercase">
              <strong><i class="fe fe-list"></i> TOTAL DATA : {{ isTotalAll }}</strong>
            </div>
          </div>
          <div class="col-sm-6 text-center" *ngIf="isTotalAll > isTotalVw">
            <div class="text-center" (click)="loadMore()">
              <a href="javascript:;" class="btn btn-xs btn-outline-primary" >Load more..</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- LIST MEMBER -->
<div class="modal fade" id="formMember" aria-labelledby="formMemberLabel" aria-hidden="true" data-keyboard="false" data-backdrop="static">
  <div class="modal-dialog modal-lg" style="max-width: 1000px !important;">
    <div class="modal-content">
      <div class="modal-header">
        <h6 class="modal-title" id="formMemberLabel">MEMBER</h6>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <div class="row" *ngIf="isMember">
          <ng-container *ngFor="let v of isMember; let i = index">
            <div class="col-lg-4">
              <div class="card" data-bs-toggle="modal" data-bs-target="#DetailMember" (click)="onMemberDetail(v.id)">
                <div class="card-body">
                  <div class="row">
                    <div class="col">
                      <div class="media">
                        <img *ngIf="v.user.photo" src="{{v.user.photo}}" alt="user" class="rounded-circle thumb-lg align-self-center">
                        <img *ngIf="!v.user.photo" src="assets/images/users/user-1.jpg" alt="user" class="rounded-circle thumb-lg align-self-center">
                        <div class="media-body ms-3 align-self-center">
                          <h5 class="mt-0 mb-1">{{v.user.name}}</h5>
                          <p class="mb-0 text-muted">
                            <a href="javascript:;" class="btn btn-xs btn-dark"> <i class="fa fa-eye"></i> Detail Member</a>
                          </p>
                        </div> 
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </ng-container>
        </div>
        <div class="row" *ngIf="!isMember">
          <div class="text-center">TIDAK ADA DATA</div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-soft-danger btn-md" data-bs-dismiss="modal">CLose</button>
      </div>
    </div>
  </div>
</div>

<!-- DETAIL MEMBER -->
<div class="modal fade" id="formDetailMember" aria-labelledby="formDetailMemberLabel" aria-hidden="true" data-keyboard="false" data-backdrop="static">
  <div class="modal-dialog modal-lg" style="max-width: 1000px !important;">
    <div class="modal-content">
      <div class="modal-header">
        <h6 class="modal-title" id="formDetailMemberLabel">DETAIL MEMBER</h6>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <div class="row" *ngIf="isMemberDetail">
          <ng-container *ngFor="let v of isMemberDetail; let i = index">
            <div class="col-lg-4">
              <div class="card">
                <div class="card-body">
                  <div class="row">
                    <div class="col">
                      <div class="media">
                        <div class="media-body ms-3 align-self-center">
                          <p class="mb-0 text-muted">
                            <a href="javascript:;" class="btn btn-xs btn-dark"> <i class="far fa-money-bill-alt"></i> Tenor {{v.tenor}}</a>
                          </p>
                          <table>
                            <tr>
                              <th>Class Fee</th>
                              <td>:</td>
                              <td>{{v.class_fee}}</td>
                            </tr>
                            <tr>
                              <th>Admin Fee</th>
                              <td>:</td>
                              <td>{{v.admin_fee}}</td>
                            </tr>
                            <tr>
                              <th>Payment Method</th>
                              <td>:</td>
                              <td>{{v.payment_method}}</td>
                            </tr>
                            <tr>
                              <th>Payment Number</th>
                              <td>:</td>
                              <td>{{v.payment_number}}</td>
                            </tr>
                            <tr>
                              <th>Payment Status</th>
                              <td>:</td>
                              <td>{{v.status}}</td>
                            </tr>
                          </table>
                        </div> 
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </ng-container>
        </div>
        <div class="row" *ngIf="!isMemberDetail">
          <div class="text-center">TIDAK ADA DATA</div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-soft-danger btn-md" data-bs-dismiss="modal">CLose</button>
      </div>
    </div>
  </div>
</div>