import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { FormatService } from '../../services/format/format.service';
import configJson from '../../../assets/config.json';

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  IS_API: string = configJson.apiUrl;
  LIMIT: number = configJson.limit;
  constructor(
    private http: HttpClient,
    private formatService: FormatService,
    private toastr: ToastrService,
  ) { }

  methodGet(ctrl: string, token: string): Observable<any> {
    this.formatService.blockToggle();
    const isHead = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}`}) }
    return this.http.get(`${this.IS_API}${ctrl}`, isHead)
      .pipe(
        map(results => {
          this.formatService.blockToggle(false);
          return results;
        }),
        catchError(async (error) => {
          this.formatService.blockToggle(false);
          this.toastr.error(`${error.error.status.statusCode} ${error.error.status.statusDesc}`);
        })
      );
  }

  methodPost(ctrl: string, objectData: any, token: string): Observable<any> {
    this.formatService.blockToggle();
    const isHead = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}`}) }
    return this.http
      .post(`${this.IS_API}${ctrl}`, objectData, isHead)
      .pipe(
        map(results => {
          this.formatService.blockToggle(false);
          return results;
        }),
        catchError(async (error) => {
          this.formatService.blockToggle(false);
          this.toastr.error(`${error.error.status.statusCode} ${error.error.status.statusDesc}`);
        })
      );
  }

  methodPut(ctrl: string, objectData: any, token: string): Observable<any> {
    this.formatService.blockToggle();
    const isHead = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}`}) }
    return this.http
      .put(`${this.IS_API}${ctrl}`, objectData, isHead)
      .pipe(
        map(results => {
          this.formatService.blockToggle(false);
          return results;
        }),
        catchError(async (error) => {
          this.formatService.blockToggle(false);
          this.toastr.error(`${error.error.status.statusCode} ${error.error.status.statusDesc}`);
        })
      );
  }

  methodPostWithFormdata(ctrl: string, objectData: any, token: string): Observable<any> {
    const isHead = { headers: new HttpHeaders({ 'Authorization': `Bearer ${token}`}) }
    return this.http
      .post(`${this.IS_API}${ctrl}`, objectData, isHead)
      .pipe(
        map(results => {
          return results;
        })
      );
  }

  methodPutWithFormdata(ctrl: string, objectData: any, token: string): Observable<any> {
    const isHead = { headers: new HttpHeaders({ 'Authorization': `Bearer ${token}`}) }
    return this.http
      .put(`${this.IS_API}${ctrl}`, objectData, isHead)
      .pipe(
        map(results => {
          return results;
        })
      );
  }

  methodDelete(ctrl: string, token: string): Observable<any>  {
    this.formatService.blockToggle();
    const isHead = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` }) }
    return this.http
      .delete(`${this.IS_API}${ctrl}`, isHead)
      .pipe(
        map(results => {
          this.formatService.blockToggle(false);
          return results;
        }),
        catchError(async (error) => {
          this.formatService.blockToggle(false);
          this.toastr.error(`${error.error.status.statusCode} ${error.error.status.statusDesc}`);
        })
      );
  }
}
