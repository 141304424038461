import { Component,OnInit } from '@angular/core';

@Component({
  selector: 'app-block-temp',
  styles: [],
  template: `
  <div class="block-ui-template">
    <img src="assets/images/preloaders/3.svg" class="img-load" alt="Loader">
  </div>
  `
})
export class BlockComponent implements OnInit {

  ngOnInit(): void {
  //  console.log("masuk");
  }
 }