import {Injectable} from '@angular/core';
import {BlockUI} from 'ng-block-ui';
import Swal from 'sweetalert2';
import 'sweetalert2/dist/sweetalert2.css';

@Injectable({
  providedIn: 'root'
})
export class FormatService {
  @BlockUI('contact-list') blockUIList: any;

  constructor() { }

  parseFormData(formData:any, objData:any){
    Object.keys(objData).forEach(a => {
      if(typeof objData[a] === 'object'){
        Object.keys(objData[a]).forEach((b) => {
          if(typeof objData[a][b] === 'object'){
            Object.keys(objData[a][b]).forEach((c) => {
              formData.append(`${a}[${b}][${c}]`, objData[a][b][c]);
            });
          }else{
            formData.append(`${a}[${b}]`, objData[a][b]);
          }
        });
      }else{
        formData.append(a, objData[a]);
      }
    });

    return formData;
  }

  alertBeforeDelete(){
    return Swal.fire({
      title: 'Apakah Anda yakin?',
      icon: 'warning',
      allowOutsideClick: false,
      backdrop: true,
      showCancelButton: true,
      confirmButtonText: '<i class="ft-check"></i>&nbsp;Yakin!',
      cancelButtonText: '<i class="ft-x"></i>&nbsp;Tidak',
    });
  }

  blockToggle(active: boolean = true){
    (active
      ? this.blockUIList.start()
      : setTimeout(() => {
        this.blockUIList.stop();
      }, 1000)
    );
  }

  truncate(length: number, input: string){
    return (input.length > length ? `${input.substring(0, length)}...` : input);
  }

  uniqueCode(lengthCode: number){
    let code = "";
    let possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
    for (let i = 0; i < lengthCode; i++)
      code += possible.charAt(Math.floor(Math.random() * possible.length));
    return code;
  }

  currency(number){
    return "Rp"+this.dotFormat(number);
  }

  dotFormat(number){
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
  }
}
