import {Component, Output, Input, OnInit, EventEmitter} from '@angular/core';
import {FormGroup, FormControl} from '@angular/forms';
import {ApiService} from '../../services/api/api.service';
import {AuthenticationService} from '../../services/auth/authentication.service';
import {ActivatedRoute} from "@angular/router";

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.css']
})
export class SearchComponent implements OnInit {
  @Output() isSearch = new EventEmitter<any>();
  @Output() isReset = new EventEmitter<any>();
  @Output() isFormAdd = new EventEmitter<any>();
  @Input() isFormSrc: any;
  searchForm = new FormGroup({
    keyword: new FormControl(''),
    type: new FormControl(''),
    status: new FormControl(''),
    surah: new FormControl('')
  });
  isForm: number = 0;
  dataType: any = [{'id': 1, 'text': 'MASAL'}, {'id': 0, 'text': 'UNIK'}];
  dataStatus: any = [{'id': 1, 'text': 'ACTIVE'}, {'id': 0, 'text': 'NOT ACTIVE'}];
  dataStatusApproval: any = [{'id': 'approved', 'text': 'Sudah Diperbaiki'}, {'id': "submitted", 'text': 'Belum Diperbaiki'}];
  dataSurah: any = [];

  constructor(
    private authService: AuthenticationService,
    private apiService: ApiService,
    private route: ActivatedRoute,
  ) {
  }

  async ngOnInit(): Promise<void> {
    this.isForm = this.isFormSrc.isFormSearch;
    if ((this.isForm == 3)) {
      await this.getSurah()
      this.route.queryParams
        .subscribe(params => {
            if (params.id) {
              this.searchForm.patchValue({'surah': params.id})
            }
          }
        );
      this.onSearch();
    }
  }

  onSearch() {
    this.isSearch.emit(this.searchForm.value);
  }

  onReset() {
    this.searchForm = new FormGroup({
      keyword: new FormControl(''),
      type: new FormControl(''),
      status: new FormControl(''),
      surah: new FormControl('')
    });
    this.isReset.emit('');
  }

  onShowAdd() {
    this.isFormAdd.next();
  }

  async getSurah() {
    try {
      this.apiService.methodGet(`surah`, this.authService.getToken()).subscribe((response) => {
        if (response.status == 200) {
          this.dataSurah = response.data;
        }
      })
    } catch (error) {
      console.log(error);
    }
  }
}
