import {Component, OnInit} from '@angular/core';
// @ts-ignore
import ApexCharts from 'apexcharts'
import {ApiService} from "../../services/api/api.service";
import {AuthenticationService} from "../../services/auth/authentication.service";
import { FormatService } from '../../services/format/format.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {

  dataSummary = {
    audio: '...',
    user: '...',
    teacher_feed: '...',
    video: '...',
    playlist: '...',
    sahamqu: '...',
    user_voucher: '...',
    user_store: '...'
  }

  constructor(
    private authService: AuthenticationService,
    private apiService: ApiService,
    private formatService: FormatService,
  ) {
  }

  ngOnInit(): void {
    this.summary()
    this.dailyVisitChart()
    this.genderChart()
  }

  summary(){
    try {
      this.apiService.methodGet(`dashboard/summary`, this.authService.getToken()).subscribe(async (response) => {
        if (response.status == 200) {
          this.dataSummary = {
            audio: this.formatService.dotFormat(response.data.audio),
            user: this.formatService.dotFormat(response.data.user),
            teacher_feed: this.formatService.dotFormat(response.data.teacher_feed),
            video: this.formatService.dotFormat(response.data.video),
            playlist: this.formatService.dotFormat(response.data.playlist),
            sahamqu: this.formatService.currency(response.data.sahamqu),
            user_voucher: this.formatService.dotFormat(response.data.user_voucher),
            user_store: this.formatService.dotFormat(response.data.user_store),
          }
        }
      })
    } catch (error) {
      console.log(error)
    }
  }

  dailyVisitChart() {
    try {
      this.apiService.methodGet(`dashboard/daily-visit`, this.authService.getToken()).subscribe(async (response) => {
        let data = []
        if (response.status == 200) {
          data = response.data
          const options = {
            title: {
              text: 'Daily Visit',
              style: {
                fontSize: '18px'
              }
            },
            colors: ['#1abc9c'],
            series: [{
              name: "Pengunjung",
              data: data
            }],
            chart: {
              id: 'area-datetime',
              type: 'area',
              height: 350,
              zoom: {
                autoScaleYaxis: true
              }
            },
            dataLabels: {
              enabled: false
            },
            markers: {
              size: 0,
              style: 'hollow',
            },
            xaxis: {
              type: 'datetime',
              min: data.length > 0 ? data[0][0] : data,
              tickAmount: 10,
            },
            tooltip: {
              x: {
                format: 'dd MMM yyyy'
              }
            },
            fill: {
              type: 'gradient',
              gradient: {
                shadeIntensity: 1,
                opacityFrom: 0.7,
                opacityTo: 0.9,
                stops: [0, 100]
              }
            },
          };

          const chartArea = new ApexCharts(document.querySelector('#area'), options);
          await chartArea.render();
        }
      })
    } catch (error) {
      console.log(error)
    }
  }

  genderChart() {
    try {
      this.apiService.methodGet(`dashboard/gender`, this.authService.getToken()).subscribe(async (response) => {
        let data = []
        if (response.status == 200) {
          data = response.data
          const colorPalette = ['#008FFB', '#FF4560', '#95a5a6']
          const options = {
            chart: {
              type: 'donut',
              width: '100%',
              height: 340
            },
            dataLabels: {
              enabled: false,
            },
            plotOptions: {
              pie: {
                customScale: 0.8,
                donut: {
                  size: '75%',
                },
                offsetY: 20,
              },
              stroke: {
                colors: undefined
              }
            },
            colors: colorPalette,
            title: {
              text: 'Jenis Kelamin',
              style: {
                fontSize: '18px'
              }
            },
            series: data.length > 0 ? data : [0, 0, 0],
            labels: ['Laki-laki', 'Perempuan', 'Data Kosong'],
            legend: {
              position: 'left',
              offsetY: 80
            }
          }

          const donut = new ApexCharts(
            document.querySelector("#donut"),
            options
          )
          await donut.render();
        }
      })
    } catch
      (error) {
      console.log(error)
    }
  }


}
