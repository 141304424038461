import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, FormArray, FormControl, Validators} from '@angular/forms';
import {ToastrService} from 'ngx-toastr';
import {AuthenticationService} from '../../services/auth/authentication.service';
import {ApiService} from '../../services/api/api.service';
import {FormatService} from '../../services/format/format.service';
import * as moment from 'moment';
import {ActivatedRoute, Router} from "@angular/router";

declare const $: any;

@Component({
  selector: 'app-audio',
  templateUrl: './audio-file.component.html',
  styleUrls: ['./audio-file.component.css']
})
export class AudioFileComponent implements OnInit {

  showFormAdd: any = {'isFormSearch': 3, 'isType': 'add',};
  isForm: any;
  isDetailData: any;
  isData: any = [];
  isOffset: number = 0;
  isTotalVw: number = 0;
  isTotalAll: number = 0;

  isKeyword: string = '';
  isSurah: string = '';

  attachment: any = [];
  dataSurah: any = [];

  isLoading = false;
  disableSelection = false;

  constructor(
    private fb: FormBuilder,
    private toastr: ToastrService,
    private authService: AuthenticationService,
    private apiService: ApiService,
    private formatService: FormatService,
    private route: ActivatedRoute
  ) {

  }

  ngOnInit(): void {
    this.resetForm();
    this.getSurah();
    this.route.queryParams
      .subscribe(params => {
          if (params.id) {
            this.disableSelection = true;
          }
        }
      );
  }

  async resetForm() {
    this.isForm = this.fb.group({
      id: new FormControl(''),
      surah: new FormControl('', [Validators.required]),
      ayah: new FormControl('', [Validators.required]),
      file: new FormControl('', [Validators.required]),
    })
  }

  async getSurah() {
    try {
      this.apiService.methodGet(`surah`, this.authService.getToken()).subscribe((response) => {
        if (response.status == 200) {
          this.dataSurah = response.data;
        }
      })
    } catch (error) {
      console.log(error);
    }
  }

  async onReset() {
    this.isData = await [];
    this.isOffset = await 0;
    this.isTotalVw = await 0;
    this.isTotalAll = await 0;
    this.isKeyword = await '';
    this.isSurah = await '';
    await this.resetForm();
    await this.getData();
  }

  async onSearch($event: any) {
    this.isData = await [];
    this.isOffset = await 0;
    this.isTotalVw = await 0;
    this.isTotalAll = await 0;
    this.isKeyword = await ($event['keyword'] ? $event['keyword'] : '');
    this.isSurah = await ($event['surah'] ? $event['surah'] : '');
    this.getData();
  }

  async onDelete(id: number) {
    try {
      await this.formatService.alertBeforeDelete().then(async (result) => {
        if (result.value) {
          this.apiService.methodDelete(`audio/${id}`, this.authService.getToken()).subscribe(async (response) => {
            if (response.status == 200) {
              await this.toastr.success(`Berhasil dihapus`);
              this.onReset();
            }
          });
        }
      });
    } catch (error) {
      console.log(error);
    }
  }

  async getData() {
    let searchData = {"keyword": this.isKeyword, "surah": this.isSurah};
    try {
      this.apiService.methodGet(`audio/file/${this.isOffset}/${encodeURIComponent(JSON.stringify(searchData))}`, this.authService.getToken()).subscribe((response) => {
        if (response.status == 200) {
          (response.total ? response.data.forEach((element: any) => {
            element.url = `${response.directoryFile}audio/${element.file}`;
            element.updatedAt = moment(element.updatedAt).locale('id').format("D MMMM YYYY HH:mm");
            this.isData.push(element);
          }) : false);
          this.isTotalVw = this.isData.length;
          this.isTotalAll = response.total;
        }
      })
    } catch (error) {
      console.log(error);
    }
  }

  async loadMore() {
    this.isOffset = await this.isTotalVw;
    await this.getData();
  }

  async onShowAdd() {
    await this.resetForm();
    $('#formData').modal({backdrop: 'static', keyboard: false})
    this.route.queryParams
      .subscribe(params => {
          if (params.id) {
            this.disableSelection = true;
            this.isForm.patchValue({surah: params.id})
          }
        }
      );
  }

  async onSubmit() {
    try {
      if (this.isForm.valid) {
        this.isLoading = true;
        let formValue = await new FormData();
        formValue.append('id', this.isForm.value['id']);
        formValue.append('admin_id', this.authService.getSession().id);
        formValue.append('surah', this.isForm.value['surah']);
        formValue.append('ayah', this.isForm.value['ayah']);
        formValue.append('file', this.isForm.value['file']);
        console.log(formValue)
        if (this.isForm.value.id) {
          this.apiService.methodPutWithFormdata(`audio/${this.isForm.value.id}`, formValue, this.authService.getToken()).subscribe(async (response) => {
            $('#formData').modal('hide');
            this.isLoading = false;
            if (response.status == 200) {
              await this.toastr.success(`Berhasil diupdate`);
              await this.onReset();
            } else {
              await this.toastr.error(response.message || "Gagal memuat data");
            }
          });
        } else {
          this.apiService.methodPostWithFormdata(`audio`, formValue, this.authService.getToken()).subscribe(async (response) => {
            $('#formData').modal('hide');
            this.isLoading = false;
            if (response.status == 200) {
              await this.toastr.success(`Berhasil disimpan`);
              await this.onReset();
            } else {
              await this.toastr.error(response.message || "Gagal memuat data");
            }
          });
        }
      }
    } catch (error) {

    }
  }

  async onEdit(i: number) {
    try {
      this.resetForm();
      const dataEdit: any = Object.assign({}, this.isData[i]);
      await this.isForm.patchValue(dataEdit);
      this.isForm.controls['file'].clearValidators();
      this.isForm.controls['file'].updateValueAndValidity();
      await $('#formData').modal({backdrop: 'static', keyboard: true, show: true});
    } catch (error) {
      console.log(error);
    }
  }

  async onFileSelect(e, n) {
    if (e.target.files.length > 0) {
      this.attachment = e.target.files[0];
      this.isForm.value[n] = this.attachment;
    }
  }
}
