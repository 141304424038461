<!-- Log In page -->
<div class="container ">
  <div class="row vh-100 d-flex justify-content-center">
    <div class="col-12 align-self-center">
      <div class="row">
        <div class="col-lg-5 mx-auto">
          <div class="card">
            <div class="card-body p-0 auth-header-box">
              <div class="text-center p-3">
                <a class="logo logo-admin">
                  <img src="../../../assets/images/hatam_logo.svg" height="50" alt="logo" class="auth-logo">
                </a>
                <!-- <h4 class="mt-3 mb-1 fw-semibold text-white font-18">Let's Get Started Hatam</h4> -->
                <p class="text-muted  mb-0">Sign in to continue to Hatam.</p>
              </div>
            </div>
            <div class="card-body p-1">
              <!-- Tab panes -->
              <div class="tab-content">
                <div class="tab-pane active p-3" id="LogIn_Tab" role="tabpanel">   
                    <form [formGroup]="credentials" (ngSubmit)="login()" class="form-horizontal auth-form">
    
                      <div class="form-group mb-2">
                        <label class="form-label" for="username">Username</label>
                        <div class="input-group">
                          <input formControlName="username" type="text" class="form-control" id="username" placeholder="Enter username">
                        </div>
                      </div>
                      <!--end form-group-->
    
                      <div class="form-group mb-2">
                        <label class="form-label" for="userpassword">Password</label>
                        <div class="input-group">
                          <input formControlName="password" type="password" class="form-control" id="userpassword" placeholder="Enter password">
                        </div>
                      </div>
                      <!--end form-group-->
    
                      <div class="form-group row my-3">
                        <div class="col-sm-6">
                          <!-- <div class="custom-control custom-switch switch-success">
                            <input type="checkbox" class="custom-control-input" id="customSwitchSuccess">
                            <label class="form-label text-muted" for="customSwitchSuccess"> &emsp; Remember me</label>
                          </div> -->
                        </div>
                        <!--end col-->
                        <div class="col-sm-6 text-end">
                          <a routerLink="#" class="text-muted font-13"><i class="dripicons-lock"></i> Forgot password?</a>
                        </div>
                        <!--end col-->
                      </div>
                      <!--end form-group-->
    
                      <div class="form-group mb-0 row">
                        <div class="col-12">
                          <button class="btn btn-primary w-100 waves-effect waves-light" (click)="login()" type="button">Log In <i class="fas fa-sign-in-alt ms-1"></i></button>
                        </div>
                        <!--end col-->
                      </div>
                      <!--end form-group-->
                    </form>
                </div>
              </div>
            </div>
            <!--end card-body-->
            <div class="card-footer p-1">
              <div class="form-group mb-0 row">
                <div class="col-12">
                  <p class="text-muted text-center">Copyright © 2021 Hatam v.1. All Rights Reserved</p>
                </div>
              </div>
            </div>
          </div>
          <!--end card-->
        </div>
        <!--end col-->
      </div>
      <!--end row-->
    </div>
    <!--end col-->
  </div>
  <!--end row-->
</div>
<!--end container-->
<!-- End Log In page -->