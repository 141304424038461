import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormArray, FormControl, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { AuthenticationService } from '../../services/auth/authentication.service';
import { ApiService } from '../../services/api/api.service';
import { FormatService } from '../../services/format/format.service';
import * as moment from "moment";

declare const $:any;

@Component({
  selector: 'app-subscribe-store',
  templateUrl: './subscribe-store.component.html',
  styleUrls: ['./subscribe-store.component.css']
})
export class SubscribeStoreComponent implements OnInit {
  showFormAdd: any = { 'isFormSearch': 1, 'isType': '',};
  isData : any = [];
  isOffset: number = 0;
  isTotalVw : number = 0;
  isTotalAll : number = 0;

  isKeyword : string = '';

  constructor(
    private fb: FormBuilder,
    private toastr: ToastrService,
    private authService: AuthenticationService,
    private apiService: ApiService,
    private formatService: FormatService,
  ) { }

  ngOnInit(): void {
    this.getData();
  }
  async onReset() {
    this.isData    = await [];
    this.isOffset = await 0;
    this.isTotalVw = await 0;
    this.isTotalAll = await 0;
    this.isKeyword = await '';
    await this.getData();
  }
  async onSearch($event: any){
    this.isData    = await [];
    this.isOffset = await 0;
    this.isTotalVw = await 0;
    this.isTotalAll = await 0;
    this.isKeyword = await ($event['keyword']?$event['keyword']:'');
    this.getData();
  }
  async getData(){
    let searchData = {"keyword":this.isKeyword};
    try{
      this.apiService.methodGet(`user-store/${this.isOffset}/${encodeURIComponent(JSON.stringify(searchData))}`,this.authService.getToken()).subscribe((response)=>{
        if(response.status == 200) {
          (response.total ? response.data.forEach((element: any) => {
            element.createdAt = moment(element.createdAt).locale('id').format("D MMMM YYYY");
            element.expired_date = moment(element.expired_date).locale('id').format("D MMMM YYYY");
            this.isData.push(element);
          }) : false);
          this.isTotalVw = this.isData.length;
          this.isTotalAll = response.total;
        }
      })
    }catch(error){
      console.log(error);
    }
  }
  async loadMore() {
    this.isOffset = await this.isTotalVw;
    await this.getData();
  }
}
