<div class="row">
  <div class="col-lg-12">
    <div class="card">
      <div class="card-body">
        <app-search (isReset)="onReset()" (isSearch)="onSearch($event)" [isFormSrc]="showFormAdd"></app-search>
        <div class="row">
          <div class="col-lg-12">
            <div class="table-responsive">
              <table class="table table-hover mb-0">
                <thead class="thead-light">
                  <tr>
                      <th class="text-center" [width]="50">No</th>
                      <th class="" >User</th>
                      <th class="" >Nominal</th>
                      <th class="text-center" >Metode Transfer</th>
                      <th class="" >Nomor Pembayaran</th>
                      <th class="" >Status</th>
                      <th class="" >Doa</th>
                  </tr>
                </thead>
                <tbody>
                  <ng-container *ngFor="let v of isData; let i = index">
                    <tr>
                      <th class="text-center" >{{i + 1}}</th>
                      <td class="" >
                        {{v.user.name}}
                        <span *ngIf="v.is_anonymous" class="badge badge-soft-primary"><i class="fa fa-user-secret"></i></span>
                      </td>
                      <td class="" >{{v.nominal}}</td>
                      <td class="text-center" >{{v.payment_method}}</td>
                      <td class="" >{{v.payment_number}}</td>
                      <td class="">
                        <span *ngIf="v.status == 'settlement'" class="badge bg-success-gradient">SETTLEMENT</span>
                        <span *ngIf="v.status == 'pending'" class="badge bg-warning-gradient">PENDING</span>
                        <span *ngIf="v.status == 'failed'" class="badge bg-light-gradient">FAILED</span>
                        <span *ngIf="v.status == 'expire'" class="badge bg-danger-gradient">EXPIRE</span>
                      </td>
                      <td class="">{{v.pray}}</td>
                    </tr>
                  </ng-container>
                </tbody>
              </table><!--end /table-->
            </div>
          </div>
        </div>
        <div class="row mt-3">
          <div class="col-sm-3">
            <div class="pull-left text-uppercase">
              <strong><i class="fe fe-list"></i> TOTAL DATA : {{ isTotalAll }}</strong>
            </div>
          </div>
          <div class="col-sm-6 text-center" *ngIf="isTotalAll > isTotalVw">
            <div class="text-center" (click)="loadMore()">
              <a href="javascript:;" class="btn btn-xs btn-outline-primary" >Load more..</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
