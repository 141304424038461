import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormArray, FormControl, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { AuthenticationService } from '../../services/auth/authentication.service';
import { ApiService } from '../../services/api/api.service';
import { FormatService } from '../../services/format/format.service';

declare const $:any;

@Component({
  selector: 'app-voucher-backgroud',
  templateUrl: './voucher-backgroud.component.html',
  styleUrls: ['./voucher-backgroud.component.css']
})
export class VoucherBackgroudComponent implements OnInit {
  showFormAdd: any = { 'isFormSearch': 0, 'isType': 'add',};
  isForm : any;
  isDetailData : any ;
  isData : any = [];
  isOffset: number = 0;
  isTotalVw : number = 0;
  isTotalAll : number = 0;

  isKeyword : string = '';
  isLoading = false;

  attachment: any = [];
  constructor(
    private fb: FormBuilder,
    private toastr: ToastrService,
    private authService: AuthenticationService,
    private apiService: ApiService,
    private formatService: FormatService,
  ) { }

  ngOnInit(): void {
    this.getData();
    this.resetForm();
  }
  async resetForm(){
    this.isForm = this.fb.group({
      id : new FormControl(''),
      name : new FormControl('', [Validators.required]),
      images : new FormControl('', [Validators.required]),
    })
  }
  async onReset() {
    this.isData    = await [];
    this.isOffset = await 0;
    this.isTotalVw = await 0;
    this.isTotalAll = await 0;
    this.isKeyword = await '';
    await this.resetForm();
    await this.getData();
  }
  async onSearch($event: any){
    this.isData    = await [];
    this.isOffset = await 0;
    this.isTotalVw = await 0;
    this.isTotalAll = await 0;
    this.isKeyword = await ($event['keyword']?$event['keyword']:'');
    this.getData();
  }
  async onDelete(id:number){
    try{
      await this.formatService.alertBeforeDelete().then(async (result) => {
        if (result.value) {
          this.apiService.methodDelete(`voucher-background/${id}`, this.authService.getToken()).subscribe(async (response)=>{
            if(response.status == 200) {
              await this.toastr.success(`Berhasil dihapus`);
              this.onReset();
            }
          });
        }
      });
    }catch(error){
      console.log(error);
    }
  }
  async getData(){
    let searchData = {"keyword":this.isKeyword};
    try{
      this.apiService.methodGet(`voucher-background/${this.isOffset}/${encodeURIComponent(JSON.stringify(searchData))}`,this.authService.getToken()).subscribe((response)=>{
        if(response.status == 200) {
          (response.total ? response.data.forEach((element: any) => {
            element.image = `${response.directoryFile}voucher/${element.image}`
            this.isData.push(element);
          }) : false);
          this.isTotalVw = this.isData.length;
          this.isTotalAll = response.total;
        }
      })
    }catch(error){
      console.log(error);
    }
  }
  async loadMore() {
    this.isOffset = await this.isTotalVw;
    await this.getData();
  }
  async onShowAdd(){
    this.resetForm();
    $('#formData').modal({backdrop: 'static', keyboard: false})
  }
  async onSubmit(){
    try{
      if(this.isForm.valid){
        this.isLoading = true;
        let formValue = await new FormData();
        formValue.append('id', this.isForm.value['id']);
        formValue.append('admin_id', this.authService.getSession()['id']);
        formValue.append('name', this.isForm.value['name']);
        formValue.append('image', this.isForm.value['images']);
        if(this.isForm.value.id){
          this.apiService.methodPutWithFormdata(`voucher-background/${this.isForm.value.id}`, formValue, this.authService.getToken()).subscribe(async(response)=>{
            $('#formData').modal('hide');
            this.isLoading = false;
            if (response.status == 200) {
              await this.toastr.success(`Berhasil diupdate`);
              await this.onReset();
            } else {
              await this.toastr.error(response.message || "Gagal memuat data");
            }
          });
        }else{
          this.apiService.methodPostWithFormdata(`voucher-background`, formValue, this.authService.getToken()).subscribe(async(response)=>{
            $('#formData').modal('hide');
            this.isLoading = false;
            if (response.status == 200) {
              await this.toastr.success(`Berhasil disimpan`);
              await this.onReset();
            } else {
              await this.toastr.error(response.message || "Gagal memuat data");
            }
          });
        }
      }
    }catch(error){

    }
  }
  async onEdit(i:number){
    try{
      this.resetForm();
      const dataEdit: any = Object.assign({}, this.isData[i]);
      await this.isForm.patchValue(dataEdit);
      this.isForm.controls['images'].clearValidators();
      this.isForm.controls['images'].updateValueAndValidity();
      await $('#formData').modal({ backdrop: 'static', keyboard: true, show: true });
    }catch(error){
      console.log(error);
    }
  }
  async onFileSelect(e,n) {
    if (e.target.files.length > 0) {
      this.attachment        = e.target.files[0];
      this.isForm.value[n] = this.attachment;
    }
  }
}
