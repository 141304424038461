<div class="row">
  <div class="col-lg-12">
    <div class="card">
      <div class="card-body">
        <app-search (isReset)="onReset()" (isSearch)="onSearch($event)" [isFormSrc]="showFormAdd"></app-search>
        <div class="row">
          <div class="col-lg-12">
            <div class="table-responsive">
              <table class="table table-hover mb-0">
                <thead class="thead-light">
                  <tr>
                      <th class="text-center" [width]="50">No</th>
                      <th class="text-center">Foto</th>
                      <th class="" >Nama</th>
                      <th class="" >Email</th>
                      <th class="" ></th>
                      <th class="" >Username</th>
                      <th class="" >Verifikasi Email</th>
                      <th class="text-center">Status</th>
                      <th class="" >Terakhir Login</th>
                      <th class="" >Nomor Handphone</th>
                      <th class="" >Tanggal Lahir</th>
                      <th class="" >Jenis Kelamin</th>
                  </tr>
                </thead>
                <tbody>
                  <ng-container *ngFor="let v of isData; let i = index">
                    <tr>
                      <th class="text-center" >{{i + 1}}</th>
                      <td class="text-center" >
                          <img *ngIf="v.photo" src="{{v.photo}}" alt="" class="rounded-circle thumb-xs me-1" onerror="this.onerror=null;this.src='../assets/images/no_avatar.webp';"
                          >
                      </td>
                      <td class="text-left" >{{v.name}}</td>
                      <td class="text-left" >{{v.email}}</td>
                      <td class="text-right">
                        <span *ngIf="v.sign_with == 'google' " class="badge bg-danger-gradient">GOOGLE</span>
                        <span *ngIf="v.sign_with == 'facebook' " class="badge bg-primary-gradient">FACEBOOK</span>
                        <span *ngIf="v.sign_with == 'apple'"  class="badge bg-dark-gradient">APPLE</span>
                      </td>
                      <td class="text-center" >{{v.username}}</td>
                      <td class="text-center" >
                        <span *ngIf="v.is_verification == 1" class="badge bg-success-gradient">VERIFIED</span>
                        <span *ngIf="v.is_verification == 0" class="badge bg-light-gradient">UNVERIFIED</span>
                      </td>
                      <td class="text-center" >
                        <span *ngIf="v.status == 1" class="badge bg-success-gradient">ACTIVE</span>
                        <span *ngIf="v.status == 0" class="badge bg-warning-gradient">BANNED</span>
                        <span *ngIf="v.status == 2" class="badge bg-light-gradient">DELETED</span>
                      </td>
                      <td class="text-center" >{{v.last_sign}}</td>
                      <td class="text-center" >{{v.phone_number}}</td>
                      <td class="text-center" >{{v.birth_date}}</td>
                      <td class="text-center" >{{v.gender}}</td>
                    </tr>
                  </ng-container>
                </tbody>
              </table><!--end /table-->
            </div>
          </div>
        </div>
        <div class="row mt-3">
          <div class="col-sm-3">
            <div class="pull-left text-uppercase">
              <strong><i class="fe fe-list"></i> TOTAL DATA : {{ isTotalAll }}</strong>
            </div>
          </div>
          <div class="col-sm-6 text-center" *ngIf="isTotalAll > isTotalVw">
            <div class="text-center" (click)="loadMore()">
              <a href="javascript:;" class="btn btn-xs btn-outline-primary" >Load more..</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
