<div class="row">
  <div class="col-lg-12">
    <div class="card">
      <div class="card-body">
        <app-search (isFormAdd)="onShowAdd()" (isReset)="onReset()" (isSearch)="onSearch($event)" [isFormSrc]="showFormAdd"></app-search>
        <div class="row">
          <div class="col-lg-12">
            <div class="table-responsive">
              <table class="table table-hover mb-0">
                <thead class="thead-light">
                  <tr>
                      <th class="text-center" [width]="50">No</th>
                      <th class="" >Gambar</th>
                      <th class="" >Judul</th>
                    <th class="text-center" [width]="150">Aksi</th>
                  </tr>
                </thead>
                <tbody>
                  <ng-container *ngFor="let v of isData; let i = index">
                    <tr>
                      <th class="text-center" >{{i + 1}}</th>
                      <td class="" >
                        <img *ngIf="v.image" src="{{v.image}}" alt="" class="" width="100">
                      </td>
                      <td class="text-left" >{{v.name}}</td>
                      <td class="text-center" >
                        <a class="btn btn-xs btn-warning text-light mr-5" (click)="onEdit(i)" data-bs-toggle="modal" data-bs-target="#formData"> <i class="fa fa-edit"></i> Edit</a>
                        <a class="btn btn-xs btn-danger mr-5" (click)="onDelete(v.id)"> <i class="fa fa-trash"></i> Hapus</a>
                      </td>
                    </tr>
                  </ng-container>
                </tbody>
              </table><!--end /table-->
            </div>
          </div>
        </div>
        <div class="row mt-3">
          <div class="col-sm-3">
            <div class="pull-left text-uppercase">
              <strong><i class="fe fe-list"></i> TOTAL DATA : {{ isTotalAll }}</strong>
            </div>
          </div>
          <div class="col-sm-6 text-center" *ngIf="isTotalAll > isTotalVw">
            <div class="text-center" (click)="loadMore()">
              <a href="javascript:;" class="btn btn-xs btn-outline-primary" >Load more..</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- FORM -->
<form [formGroup]="isForm" (ngSubmit)="onSubmit()">
  <div class="modal fade" id="formData" aria-labelledby="formDataLabel" aria-hidden="true" data-keyboard="false" data-backdrop="static">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h6 class="modal-title" id="formDataLabel">FORM DATA</h6>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-lg-12">
              <div class="mb-3 row">
                <label for="nama_surah" class="col-sm-2 form-label align-self-center mb-lg-0 text-end">Judul *</label>
                <div class="col-sm-8">
                  <input type="text" id="nama_surah" class="form-control" formControlName="name">
                </div>
              </div>
            </div>
            <div class="col-lg-12">
              <div class="mb-3 row">
                <label for="gambar" class="col-sm-2 form-label align-self-center mb-lg-0 text-end">Upload Gambar *</label>
                <div class="col-sm-8">
                    <input class="form-control" type="file" id="gambar" formControlName="images" (change)="onFileSelect($event,'images')">
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="submit" class="btn btn-soft-primary btn-md" [disabled]="!isForm.valid"> <i class="fa fa-spinner fa-spin" *ngIf="isLoading"></i> Simpan</button>
          <button type="button" class="btn btn-soft-danger btn-md" data-bs-dismiss="modal">Batal</button>
        </div>
      </div>
    </div>
  </div>
</form>
