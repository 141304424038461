<div class="row">
  <div class="col-lg-12">
    <div class="card">
      <div class="card-body">
        <app-search (isReset)="onReset()" (isSearch)="onSearch($event)" [isFormSrc]="showFormAdd"></app-search>
        <div class="row">
          <div class="col-lg-12">
            <div class="table-responsive">
              <table class="table table-hover mb-0">
                <thead class="thead-light">
                  <tr>
                      <th class="text-center" [width]="50">No</th>
                      <th class="text-center" >Action</th>
                      <th class="" >Admin</th>
                      <th class="" >Group Name</th>
                      <th class="" >Description</th>
                      <th class="" >Reading</th>
                      <th class="" >Photo</th>
                  </tr>
                </thead>
                <tbody>
                  <ng-container *ngFor="let v of isData; let i = index">
                    <tr>
                      <th class="text-center" >{{i + 1}}</th>
                      <td class="text-center">
                        <a class="btn btn-xs btn-success text-light mr-5" (click)="onMember(v.id)"> <i class="fa fa-users"></i> Member</a>
                      </td>
                      <td class="" >{{v.user.name}}</td>
                      <td class="" >{{v.name}}</td>
                      <td class="" >{{v.description}}</td>
                      <td class="" >{{v.reading_amount}} {{v.reading_category}}</td>
                      <td class="" >
                        <img *ngIf="v.photo" src="{{v.photo}}" alt="" class="rounded-circle thumb-xs me-1">
                      </td>
                    </tr>
                  </ng-container>
                </tbody>
              </table><!--end /table-->
            </div>
          </div>
        </div>
        <div class="row mt-3">
          <div class="col-sm-3">
            <div class="pull-left text-uppercase">
              <strong><i class="fe fe-list"></i> TOTAL DATA : {{ isTotalAll }}</strong>
            </div>
          </div>
          <div class="col-sm-6 text-center" *ngIf="isTotalAll > isTotalVw">
            <div class="text-center" (click)="loadMore()">
              <a href="javascript:;" class="btn btn-xs btn-outline-primary" >Load more..</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- LIST MEMBER -->
<div class="modal fade" id="formMember" aria-labelledby="formMemberLabel" aria-hidden="true" data-keyboard="false" data-backdrop="static">
  <div class="modal-dialog modal-lg" style="max-width: 1000px !important;">
    <div class="modal-content">
      <div class="modal-header">
        <h6 class="modal-title" id="formMemberLabel">MEMBER</h6>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <div class="row" *ngIf="isMember">
          <ng-container *ngFor="let v of isMember; let i = index">
            <div class="col-lg-4">
              <div class="card">
                <div class="card-body">
                  <div class="row">
                    <div class="col">
                      <div class="media">
                        <img *ngIf="v.user.photo" src="{{v.user.photo}}" alt="user" class="rounded-circle thumb-lg align-self-center">
                        <img *ngIf="!v.user.photo" src="assets/images/users/user-1.jpg" alt="user" class="rounded-circle thumb-lg align-self-center">
                        <div class="media-body ms-3 align-self-center">
                          <h5 class="mt-0 mb-1">{{v.user.name}}</h5>
                          <p class="mb-0 text-muted">
                            <a href="javascript:;" class="btn btn-xs btn-dark"> <i class="fa fa-email"></i>{{v.user.email}}</a>
                          </p>
                        </div> 
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </ng-container>
        </div>
        <div class="row" *ngIf="!isMember">
          <div class="text-center">TIDAK ADA DATA</div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-soft-danger btn-md" data-bs-dismiss="modal">CLose</button>
      </div>
    </div>
  </div>
</div>